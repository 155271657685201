import React, { ComponentProps } from 'react';
import {
  Pagination as MantinePagination,
  PaginationItemProps,
} from '@mantine/core';
import { StyledPagination } from '~/components/Pagination/Pagination.styled';

export const Pagination: React.FC<PaginationProps> = props => {
  return (
    <StyledPagination>
      <MantinePagination {...props} />
    </StyledPagination>
  );
};

const PaginationItem: React.FC<PaginationItemProps> = ({ page, active, ...props }) => {
  const className = active ? 'pagination__item--active' : '';
  const { onClick } = props;
  if (page === "dots") {
    return <div>...</div>;
  }
  return <button className={["pagination__item", className].join(' ')} onClick={onClick}>{page}</button>;
};

interface PaginationProps extends ComponentProps<typeof MantinePagination> {}
