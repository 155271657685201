import { navigate } from 'gatsby'
import SummaryImage from '~/components/Account/Subscription/SummaryImage'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { Any, Subscription } from '../../../../types/types'
import { FC } from 'react'
import { Cta } from '~/components/Generic/Cta/Cta'

const SubscriptionCard: FC<SubscriptionCardProps> = ({ subscription }) => {
  const translate = useTranslate()

  const renewalDate =
    subscription.state !== 'canceled'
      ? new Date(subscription.actionableDate).toLocaleDateString()
      : translate('subscriptions.cancelled')
    
  let renewalText = `${subscription.interval}`.split(' ').shift() === '1' ? `${subscription.interval}`.slice(1) : `${subscription.interval}`;
  return (
    <div
    className="AccountSubscriptionCard"
    >
      <div
      className="AccountSubscriptionCard__summary"
      >
        <h2>Subscription #{subscription.id}</h2>
        <h3>Renews every {renewalText}</h3>
        <p className="AccountSubscriptionCard__creationDate">
          {translate('subscriptions.creation_date', {
            date: new Date(subscription.createdAt).toLocaleDateString(),
          })}
        </p>
        <p className="AccountSubscriptionCard__renewlDate">
          {translate('subscriptions.renewal_date', {
            date: renewalDate,
          })}
        </p>

        <div
        
        >
            {subscription &&
            subscription.lineItems &&
            subscription.lineItems.map((lineItem, index) => (
              <SummaryImage
                slug={lineItem.productSlug}
                lineItem={lineItem}
                sku={lineItem.sku}
                key={`${lineItem.sku} - ${index}`}
              />
            ))}
        </div>

        <Cta
          onClick={() => navigate(`/account/subscriptions/${subscription.id}`)}
          type="submit"
          variant="secondary"
        >
          {translate('subscriptions.manage')}
        </Cta>
      </div>
    </div>
  )
}

export default SubscriptionCard

interface SubscriptionCardProps {
  subscription: Subscription; 
}