import React, { useCallback, useEffect, useRef, useState } from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import { StyledAmbassadorResources } from './AmbassadorResources.style';
import ReactMarkdown from 'react-markdown';
import { ContentCard } from '~/types/types';
import Shape4 from '../../../assets/images/shapes/shape4.svg';
import Arrow from '~/assets/images/icons/arrow-2.svg';

import {
  SwiperOptions,
  A11y,
  Scrollbar,
  Swiper as SwiperInstance,
  Navigation,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  AmbassadorResourceCard,
  AmbassadorResourceCardItem,
} from './AmbassadorResourceCard';
import { useSwiperRef } from "~/hooks/components/use-swiper-ref";

const SWIPER_OPTS: SwiperOptions = {
  modules: [Scrollbar, Navigation, A11y],
  spaceBetween: 50,
  slidesPerView: 'auto',
  scrollbar: { draggable: true },
};
export const AmbassadorResources = () => {
  return (
    <StyledAmbassadorResources>
      <StaticQuery
        query={graphql`
          query AmbassadorResources {
            contentfulAmbassadorPortal {
              resources: section(id: "ambassadorResources") {
                title: settingValueAsText(id: "title")
                resource_title: settingValueAsText(id: "resource_title")
                resource_left: settingValueAsText(id: "resource_left")
                resource_right: settingValueAsText(id: "resource_right")
              }
            }
            allContentfulAmbassadorEvent(
              sort: { fields: [date], order: DESC }
            ) {
              edges {
                node {
                  ...AmbassadorEventCardFragment
                }
              }
            }
          }
        `}
        render={data => {
          const news = data?.allContentfulAmbassadorEvent?.edges?.map(
            ({ node }) => node
          );

          const [swiper, setSwiper] = useState<SwiperInstance | null>(null);
          const [leftMargin, setLeftMargin] = useState<number>(0);
          
          const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();
          const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();

          useEffect(() => {
            if (typeof window === 'undefined' || !swiper) {
              return;
            }
          }, [swiper]);

          const updateGutter = useCallback(() => {
            if (!swiper) {
              return;
            }
            const targetElement = document.querySelector(
              '.AmbassadorResources__carouselTitle'
            );
            const rect: DOMRect =
              targetElement.getBoundingClientRect() as DOMRect;
            setLeftMargin(rect.left + 8);
          }, [swiper]);

          const swiperOptions = {
            ...SWIPER_OPTS,
            navigation: {
              nextEl: nextEl,
              prevEl: prevEl
            },
          }

          useEffect(() => {
            if (!swiper) {
              return;
            }
            window.addEventListener('resize', updateGutter);
            updateGutter();
            return () => {
              window.removeEventListener('resize', updateGutter);
            };
          }, [swiper]);

          return (
            <div className="AmbassadorResources">
              <div
                className="AmbassadorResources__carousel"
              >
                <div className="AmbassadorResources__carouselTitle">
                  <h2>{data.contentfulAmbassadorPortal.resources.title}</h2>
                  
                  <div className="SwiperNav">
                    <button type="button" className="SwiperNav__item SwiperNav__item--prev" ref={prevElRef}>
                      <Arrow />
                    </button>

                    <button type="button" className="SwiperNav__item SwiperNav__item--next" ref={nextElRef}>
                      <Arrow />
                    </button>
                  </div>
                </div>

                <div className="AmbassadorResources__carouselContent">
                  <Swiper
                    {...swiperOptions}
                    slidesOffsetBefore={leftMargin}
                    className="carousel"
                    onSwiper={swiperInstance => setSwiper(swiperInstance)}
                  >
                    {news?.map((item: any, index: number) => {
                      return (
                        <SwiperSlide
                          key={index}
                          className="AmbassadorResources__carouselItem"
                        >
                          <AmbassadorResourceCard
                            item={item as AmbassadorResourceCardItem}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                  <div className="AmbassadorResources__actions">
                    <Link to="/account/ambassador/news">View all</Link>
                  </div>
                </div>
              </div>
              <div className="AmbassadorResources__resources">
                <div className="AmbassadorResources__resourcesTitle">
                  <h2>
                    {data.contentfulAmbassadorPortal.resources.resource_title}
                  </h2>
                </div>
                <div className="AmbassadorResources__resourcesContent">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data.contentfulAmbassadorPortal.resources.resource_left,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data.contentfulAmbassadorPortal.resources
                          .resource_right,
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }}
      />
    </StyledAmbassadorResources>
  );
};
