import React, { useState } from 'react'
import ShippingSummary from '~/components/Account/Subscription/Detail/ShippingSummary'
import SubscriptionDetailsAddressForm from '~/components/Account/Subscription/Detail/AddressForm'

const ShippingCard: React.FC<ShippingCardProps> = ({ title, subscription }) => {
  const [isFormOpen, setIsFormOpen] = useState(false)

  return (
    <div>
      <h1>{title}</h1>

      {isFormOpen ? (
        <SubscriptionDetailsAddressForm
          addressType={'shipAddress'}
          subscription={subscription}
          close={() => setIsFormOpen(false)}
        />
      ) : (
        <ShippingSummary
          openForm={() => setIsFormOpen(true)}
          subscription={subscription}
        />
      )}
    </div>
  )
}

interface ShippingCardProps {
  title: string
  subscription: any;
}

export default ShippingCard
