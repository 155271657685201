import styled from 'styled-components';
import { desktopHover, pxtorem } from '~/utils/tools';

export const StyledDownlineItem = styled.div<{
  level: number;
  hasChildren: boolean;
  isExpanded: boolean;
  totalNodes?: number;
  nodeIdx?: number;
}>`
  border-top: 1px solid ${({ theme }) => theme.colors.black}33;
  padding-top: ${pxtorem(16)};
  ${({ isExpanded }) => !isExpanded && `padding-bottom: ${pxtorem(16)}`};
  ${({isExpanded, theme, level, totalNodes, nodeIdx}) => {
    if (!isExpanded && !!totalNodes && !!nodeIdx && nodeIdx === totalNodes && level === 0) {
      return `border-bottom: 1px solid ${theme.colors.black}33;`
    }
  }}
  padding-left: ${({ level }) => pxtorem(level * 12)};

  .downline__item__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__level {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      border: 1px solid ${({ theme }) => theme.colors.black}33;
    }

    &__left {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .expand-btn {
      display: grid;
      place-items: center;
      width: 24px;
      height: 24px;
      padding: 0;
      background: none;
      border: none;
      background-color: ${({ theme }) => theme.colors.lime};
      color: ${({ theme }) => theme.colors.green};
      border-radius: 50%;
      cursor: pointer;

      ${({ theme }) =>
        desktopHover(`
            background-color: ${theme.colors.green};
            color: ${theme.colors.white};
          `)}

      &:disabled {
        color: #a4a4a4;
        background-color: #d9d9d9;
      }

      svg {
        margin-top: 2px;
        transform: rotate(-180deg);
        transition: transform 0.3s var(--cubic-ease);
      }

      &--open {
        background-color: ${({ theme }) => theme.colors.green};
        color: ${({ theme }) => theme.colors.white};

        svg {
          transform: rotate(0deg);
        }
      }
    }

    &__childcount {
      font-size: 14px;
      background-color: ${({ theme }) => theme.colors.gray};
      width: 20px;
      text-align: center;
      font-weight: 500;
    }
  }
`;

export const StyledDownlineData = styled.div<{ level: number; hasChildren: boolean }>`
  margin-left: ${({ level }) => pxtorem((level + 1) * 12)};
  font-size: 14px;
  ${({ hasChildren, theme}) => hasChildren && `border-left: 1px solid ${theme.colors.black}33;`}

  ${({ level, theme }) =>
    level === 0 && `border-bottom: 1px solid ${theme.colors.black}33;`}

  .stats {
    padding-block: ${pxtorem(24)};
    padding-left: ${pxtorem(24)};

    &__row {
      &__head {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: ${pxtorem(24)};
      }

      &__stats {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
      }
    }

    .label {
      font: 500 12px / 1.175 var(--primaryFont);
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .details-btn {
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      font: 400 ${pxtorem(16)} / 1.175 var(--primaryFont);
      color: ${({ theme }) => theme.colors.orange};
      text-decoration: underline;
      margin-top: ${pxtorem(24)};
    }
  }
`;
