import React from "react";
import CountUp, { CountUpProps } from "react-countup";
import Loader from "~/components/Loader/Loader";
import { StyledNumberTicker } from "./NumberTicker.style";

export const NumberTicker = ({title, subtitle,loading, loadingText, ...props}: any) => {
  return (
    <StyledNumberTicker>
      <h3>{title}</h3>
      {subtitle && <h4>{subtitle}</h4>}
      {loading && <Loader text={loadingText || 'Loading'} />}
      {!loading && <div className="NumberTickerValue"><CountUp {...props} /></div>}
    </StyledNumberTicker>
  );
}
interface NumberTickerProps extends any {
  title: string;
  subtitle?: string;
  loading?: boolean;
  loadingText?: string;
}