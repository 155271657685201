import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';

export const StyledAmbassadorMobileOrders = styled.div`
  &,
  .orderDetails {
    margin-top: 24px;
    .grid {
      display: grid;
      grid-template-columns: 291px 1fr;
      grid-template-areas: 'ambassador orders';
      gap: 40px;
      min-height: 480px;

      .cart,
      a {
        text-decoration: underline;
        color: var(--orange);
        cursor: pointer;
        &--open {
          color: var(--black);
        }
      }

      &__left {
        grid-area: ambassador;
        background-color: ${p => p.theme.colors.gray};
        padding: ${pxtorem(32)} ${pxtorem(24)};
        border-radius: 12px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;

        .cta__label {
          font-weight: 500;
        }

        &__content {
          display: grid;
          grid-template-areas: 'ambname' 'email' 'phone' 'id' 'detail';
          grid-template-columns: 1fr;
          gap: ${pxtorem(32)};

          > &:first-child {
            grid-area: ambname;
          }

          & > :nth-child(2) {
            grid-area: email;
          }

          & > :nth-child(3) {
            grid-area: phone;
          }

          & > :nth-child(4) {
            grid-area: id;
          }

          & > :nth-child(5) {
            grid-area: detail;
          }

          @media (max-width: 1048px) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 'ambname email' 'phone id' 'detail detail';
            margin-bottom: 24px;
          }
        }
      }
      &__loader {
        opacity: 0;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        transition: opacity 0.3s ease-in-out;
        pointer-events: none;
        .is-loading & {
          opacity: 1;
        }
      }

      &__right {
        grid-area: orders;
        width: 100%;
        &:before {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
          transition: opacity 0.3s ease-in-out;
          background-color: ${p => p.theme.colors.white};
          pointer-events: none;
        }
        &.is-loading:before {
          opacity: 0.5;
        }

        table {
          width: 100%;
          border-collapse: collapse;

          thead {
            border-bottom: 1px solid ${p => p.theme.colors.gray}33;
          }

          thead tr th {
            text-align: left;
            font: 500 12px / 1.175 var(--primaryFont);
            text-transform: uppercase;
            border-bottom: 1px solid ${p => p.theme.colors.black}33;
            padding-bottom: 24px;
          }

          tbody tr.open td {
            border-bottom: none;
          }

          tbody tr td {
            padding: 24px 0;
            border-bottom: 1px solid ${p => p.theme.colors.black}33;
          }

          .cart-items {
            width: 100%;
            & td {
              padding-top: 0;
            }
            .cart__content {
              border: 1px solid ${p => p.theme.colors.black}33;
              border-radius: 12px;
              padding-inline: 20px;

              div {
                border-bottom: 1px solid ${p => p.theme.colors.black}33;
                padding-block: 20px;

                span:first-child {
                  min-width: 20px;
                  margin-right: 8px;
                }

                &:last-child {
                  border: none;
                }
              }
            }
          }
        }
      }

      &--label {
        margin: 0;
        font: 500 12px / 1.175 var(--primaryFont);
        text-transform: uppercase;
        color: ${p => p.theme.colors.black};
        margin-bottom: 8px;
      }
    }

    .pagination {
      padding-top: 24px;
      display: flex;
      justify-content: center;

      @media (max-width: 1048px) {
        padding-bottom: 24px;
      }
    }

    @media (max-width: 1048px) {
      .grid {
        grid-template-areas: 'ambassador' 'orders';
        grid-template-columns: 1fr;
      }
    }
  }
`;

export const StyledMobileOrders = styled.div`
  &.orders {
    border-top: 1px solid ${p => p.theme.colors.black}33;
    margin-bottom: 24px;
    .order__grid {
      border-bottom: 1px solid ${p => p.theme.colors.black}33;
      padding-block: 24px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 24px;
      column-gap: 8px;

      &--open {
      }

      &__item {
        span {
          display: block;
        }

        &__title {
          text-transform: uppercase;
          font: 500 12px / 1.175 var(--primaryFont);
          margin-bottom: 12px;
        }
      }

      .cart-items {
        grid-column: span 2;

        .cart__content {
          padding-block: 24px;

          > span:first-child {
            min-width: 20px;
            margin-right: 8px;
          }
        }
      }

      a {
        color: ${({ theme }) => theme.colors.orange};
      }

      .cart-btn {
        background: none;
        cursor: pointer;
        border: none;
        padding-inline: 0;
        color: var(--orange);
        text-decoration: underline;

        &--open {
          color: var(--black);
        }
      }
    }

    .cart-items {
      width: 100%;
      border: 1px solid ${p => p.theme.colors.black}33;
      border-radius: 12px;
      .cart__content {
        padding-inline: 20px;

        &:not(:last-child) {
          border-bottom: 1px solid ${p => p.theme.colors.black}33;
        }
        div {
          padding-block: 20px;

          span:first-child {
            min-width: 20px;
            margin-right: 8px;
          }

          &:last-child {
            border: none;
          }
        }
      }
    }
  }
`;
