import {
  useTranslate,
  useSubscription,
} from '@chordcommerce/gatsby-theme-autonomy';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import SummaryImage from '~/components/Account/Subscription/SummaryImage';
import ShippingCard from '~/components/Account/Subscription/Detail/ShippingCard';
import BillingCard from '~/components/Account/Subscription/Detail/BillingCard';
import { FC, Fragment } from 'react';
import SubscriptionDetailsPauseForm from '~/components/Account/Subscription/Detail/PauseForm';
import { Any, Subscription } from '../../../../../types/types';
import { Cta } from '~/components/Generic/Cta/Cta';

const AccountSubscriptionDetailPage: FC<Any> = ({ subscriptionId }) => {
  const translate = useTranslate();
  const {
    isLoaded,
    isFetching,
    subscription,
    skipSubscription,
    cancelSubscription,
    resumeSubscription,
    pauseSubscription,
  } = useSubscription(subscriptionId);

  if (!isLoaded) return null;

  return (
    <div className="SubscriptionDetails">
      <div>
        <h1>{translate('subscriptions.details')}</h1>

        {subscription.state !== 'canceled' && (
          <SubscriptionActions
            isFetching={isFetching}
            skipSubscription={skipSubscription}
            cancelSubscription={cancelSubscription}
            isPaused={subscription.state === 'paused'}
            resumeSubscription={resumeSubscription}
            pauseSubscription={pauseSubscription}
          />
        )}

        <Addresses subscription={subscription} />

      </div>
    </div>
  );
};

 
const SubscriptionActions: FC<Any> = ({
  isFetching,
  skipSubscription,
  cancelSubscription,
  isPaused,
  resumeSubscription,
  pauseSubscription,
}) => {
  const translate = useTranslate();

  return (
    <div
      className="SubscriptionDetails__Actions"
    >
      {isFetching && <div color="inherit">{}</div>}

      {!isFetching && (
        <Fragment>
          {isPaused ? (
            <SubscriptionDetailsPauseForm
              action={(date: Date) => resumeSubscription(date)}
              actionType={'resume'}
            />
          ) : (
            <Fragment>
              <SubscriptionDetailsPauseForm
                action={(date: Date) => pauseSubscription(date)}
                actionType={'pause'}
              />
              <Cta variant="secondary" onClick={skipSubscription}>
                {translate('subscriptions.skip')}
              </Cta>
            </Fragment>
          )}
          <Cta variant="secondary" onClick={cancelSubscription}>
            {translate('subscriptions.cancel')}
          </Cta>
        </Fragment>
      )}
    </div>
  );
};

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK_KEY);

const Addresses: FC<LIProps> = ({ subscription }) => {
  const translate = useTranslate();

  return (
    <Elements stripe={stripePromise}>
      <div className="SubscriptionDetails__Addresses">
        <ShippingCard
          title={translate('subscriptions.shipping')}
          subscription={subscription}
        />
        <BillingCard
          title={translate('subscriptions.billing')}
          subscription={subscription}
        />
      </div>
    </Elements>
  );
};

const LineItems: FC<LIProps> = ({ subscription }) => {
  const translate = useTranslate();
  const { state, lineItems, intervalLength, intervalUnits, actionableDate } =
    subscription;

  return (
    <div>
      <h6>{translate('subscriptions.products')}</h6>
      <div>
        <p>
          {translate('subscriptions.status', {
            status: state,
          })}
        </p>
        <p>|</p>
        <p>
          {translate('subscriptions.auto_renew_interval', {
            length: intervalLength,
            unit: intervalUnits,
          })}
        </p>
        {actionableDate && (
          <Fragment>
            <p>|</p>
            <p>
              {translate('subscriptions.action_date', {
                action_date: actionableDate,
              })}
            </p>
          </Fragment>
        )}
      </div>
      {lineItems && (
        <div >
          {lineItems
            .sort((a, b) => a.id - b.id)
            .map((lineItem, index) => (
              <div key={index}>
                <SummaryImage slug={lineItem.productSlug} sku={lineItem.sku} />
                <div>
                  {lineItem.sku}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default AccountSubscriptionDetailPage;

interface LIProps {
  subscription: Subscription;
}