import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';

export const StyledPagination = styled.div`
  .mantine-Pagination-item {
    border: none;
    font-family: inherit;
    border-radius: 0;
    position: relative;
    overflow: hidden;
    font: 300 ${pxtorem(20)} / 1.2 var(--primaryFont);

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -100%;
      width: 100%;
      height: 1px;
      max-width: 16px;
      background-color: ${p => p.theme.colors.black};
      transition: transform 0.3s var(--cubic-ease);
    }

    &:hover {
      &:after {
        transform: translateX(250%);
      }
    }


    &[data-active='true'] {
      background-color: transparent;
      color: ${p => p.theme.colors.black};

      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;
