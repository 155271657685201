import React, { useState, useEffect } from 'react';
import { Section } from '~/styles/Section.styled';
import { Wrapper } from '~/styles/Wrapper.styled';
import { Cta } from '../Generic/Cta/Cta';
import Loader from '../Loader/Loader';
import { StyledOrderConfirmation } from './OrderConfirmation.style';
import { useCartContext } from '../CartContext/CartContext';
import { AMBASSADOR_ENROLLMENT } from '~/types/constants';

export const OrderConfirmation = ({backButton} : {backButton?: boolean}) => {
  const [order, setOrder] = useState(null);
  const { setForceAmbassadorInfo, forceAmbassadorInfo } = useCartContext();
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const params = new URLSearchParams(window.location.search.split('?').pop());
    const orderId = params.get('order');
    const token = params.get('token');
    const fetchOrder = async () => {
      try {
        const orderRequest = await fetch(
          `${process.env.GATSBY_ACS_API_URL || '/'}api/orders/${orderId}`,
          {
            headers: {
              'X-Spree-Order-Token': token,
            },
          }
        );
        const orderData = await orderRequest.json();
        if (orderData.error) {
          throw new Error(orderData.error);
        }
        setOrder(orderData);
        const hasEnrolledAmbassador = !!orderData.line_items.find(l => l?.variant?.sku === AMBASSADOR_ENROLLMENT.sku);
        if (hasEnrolledAmbassador) {
          setForceAmbassadorInfo(orderData.user_id);
        }
      } catch (e) {
        setOrder(false);
      }
    };
    fetchOrder();
  }, []);
  const items = order?.line_items || [];

  return (
    <StyledOrderConfirmation>
      <Section gutter>
        <Wrapper bg="lime" width="thin">
          {order === null && <Loader text="Getting your order details" />}
          {order === false && (
            <>
              <h1>Order not found</h1>
              <p>Your order could not be found.</p>
            </>
          )}
          {order && (
            <>
              <h1>Order Confirmation</h1>
              <p className="OrderConfirmation__subtitle">
                Your order #<em>{order.number}</em> was placed on{' '}
                <em>{new Date(order.completed_at).toLocaleDateString()}</em>.
              </p>
              <div className="OrderConfirmation__details">
                <div className="OrderConfirmation__details__shipping">
                  <h2>Shipping Address</h2>
                  <p>{order?.ship_address?.name}</p>
                  <p>
                    {order?.ship_address?.address1}{' '}
                    {order?.ship_address?.address2 && (
                      <>
                        <br />
                        {order?.ship_address?.address2}
                      </>
                    )}
                  </p>
                  <p>
                    {order?.ship_address?.city}{' '}
                    {order?.ship_address?.state_text}{' '}
                    {order?.ship_address?.zipcode}
                  </p>
                  <p>{order?.ship_address?.phone}</p>
                </div>
                { false && <div className="OrderConfirmation__details__billing">
                  <h2>Billing</h2>
                  <p>{order?.bill_address?.name}</p>
                  <p>
                    {order?.bill_address?.address1}{' '}
                    {order?.bill_address?.address2 && (
                      <>
                        <br />
                        {order?.bill_address?.address2}
                      </>
                    )}
                  </p>
                  <p>
                    {order?.bill_address?.city}{' '}
                    {order?.bill_address?.state_text}{' '}
                    {order?.bill_address?.zipcode}
                  </p>
                  <p>{order?.bill_address?.phone}</p>
                </div>}
              </div>
            </>
          )}
        </Wrapper>
      </Section>
      {order && (
        <Section gutter>
          <Wrapper bg="gray" width="thin">
            <h2 className="OrderConfirmation__itemsTitle">Order details</h2>
            <div className="OrderConfirmation__items">
              {items.map((item: any, index: number) => {
                const originalPrice = 1 * item.price * item.quantity;
                let finalLinePrice = originalPrice;
                (item.adjustments || []).filter((adj: any) => adj?.source_type?.includes?.('PromotionAction') && adj?.eligible).forEach((adj: any) => finalLinePrice += (adj.amount as any) * 1);

                let subtitle = '';
                if (item.subscriptionLineItems?.length) {
                  subtitle = `Renews every ${
                    item.subscriptionLineItems?.[0]?.interval
                  }`;
                }
                return (
                  <div className="OrderConfirmation__item" key={index}>
                    <div className="OrderConfirmation__item__image">
                      <img
                        src={item.variant?.images?.[0]?.small_url}
                        alt={item.variant?.title || ''}
                      />
                      <span className="OrderConfirmation__item__image__quantity">
                        {item.quantity}
                      </span>
                    </div>
                    <div className="OrderConfirmation__item__info">
                      <div className="OrderConfirmation__item__info__title">
                        {item.variant?.name || ''}
                        <span className="OrderConfirmation__item__info__subtitle">
                          {subtitle}
                        </span>
                      </div>
                      <div className="OrderConfirmation__item__info__price">
                        {originalPrice !== finalLinePrice ? (
                          <span className="OrderConfirmation__item__info__price__original">
                            ${originalPrice.toFixed(2)}
                          </span>
                        ) : null}
                        ${parseFloat(finalLinePrice).toFixed(2)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="OrderConfirmation__totals">
              <div className="OrderConfirmation__totalItem">
                <div className="OrderConfirmation__totalItem__title">
                  Subtotal
                </div>
                <div className={`OrderConfirmation__totalItem__price`}>
                  ${(1 * order?.item_total).toFixed(2)}
                </div>
              </div>
              {1 * order?.adjustment_total < 0 && (
                <div className="OrderConfirmation__totalItem">
                  <div className="OrderConfirmation__totalItem__title">
                    Discount
                    {(order?.adjustments || []).filter((adj: any) => adj.eligible).map(
                      (adjustment: any, index: number, arr: any[]) => {
                        const isCurrentIndex =
                          arr.map(v => v.label).indexOf(adjustment.label) ===
                          index;
                        return isCurrentIndex ? (
                          <span
                            className="OrderConfirmation__totalItem__subtitle"
                            key={index}
                          >
                            {adjustment.label}
                          </span>
                        ) : null;
                      }
                    )}
                  </div>
                  <div className={`OrderConfirmation__totalItem__price`}>
                    -${Math.abs(1 * order?.adjustment_total  - +order?.tax_total).toFixed(2)}
                  </div>
                </div>
              )}
              <div className="OrderConfirmation__totalItem">
                <div className="OrderConfirmation__totalItem__title">
                  Shipping
                </div>
                <div className={`OrderConfirmation__totalItem__price`}>
                  {order?.display_ship_total || ''}
                </div>
              </div>
              <div className="OrderConfirmation__totalItem">
                <div className="OrderConfirmation__totalItem__title">
                  Tax
                </div>
                <div className={`OrderConfirmation__totalItem__price`}>
                  {order?.display_tax_total || ''}
                </div>
              </div>
              <div className="OrderConfirmation__total">
                <div className="OrderConfirmation__total__title">Total</div>
                <div className="OrderConfirmation__total__price">
                  ${(1 * order?.total || 0).toFixed(2)}
                </div>
              </div>
            </div>
          </Wrapper>
          {forceAmbassadorInfo && (
            <div
            style={{
              textAlign: 'center',
              marginTop: '1rem',
            }}
          > 
            <Cta variant="primary" href="/account/ambassador">Continue to the ambassador dashboard</Cta>
          </div>)}
          <div
            style={{
              textAlign: 'center',
              marginTop: '1rem',
            }}
          > 
            {backButton !== false && <Cta href="/shop">Back to shop</Cta>}
          </div>
        </Section>
      )}
    </StyledOrderConfirmation>
  );
};
