import { useAuth, useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import { useLocalStorage } from '@mantine/hooks';
import { navigate } from '@reach/router';
import { graphql, StaticQuery } from 'gatsby';
import { FC, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import AuthLoginForm from '~/components/Auth/LoginForm';
import ShowOnScroll from '~/components/Generic/ShowOnScroll/ShowOnScroll';
import Loader from '../../../Loader/Loader';

const AccountLoginPage: FC<AccountLoginPageProps> = () => {
  const translate = useTranslate();
  const [lastLogin, setLastLogin] = useLocalStorage({ key: 'last-login' });
  const [hasRecentLoginAttempt, setHasRecentLoginAttempt] = useState(false);
  const isAmbassador =
    typeof window !== 'undefined' &&
    window.location.href.indexOf('ambassador') !== -1;
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    setHasRecentLoginAttempt(
      lastLogin && Date.now() - +lastLogin < 1000 * 60 * 10
    );
  }, [lastLogin]);

  useEffect(() => {
    if (hasRecentLoginAttempt) {
      setTimeout(() => setHasRecentLoginAttempt(false), 2500);
    }
  }, [hasRecentLoginAttempt]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/account');
    }
  }, [isLoggedIn]);

  return (
    <StaticQuery
      query={graphql`
        query LoginPage {
          contentfulAmbassadorPortal {
            login: section(id: "loginPage") {
              title: settingValueAsText(id: "title")
              values: blocks {
                title: settingValueAsText(id: "title")
                text: settingValueAsText(id: "content")
              }
            }
          }
        }
      `}
      render={data =>
        hasRecentLoginAttempt ? (
          <Loader text="Loading..." />
        ) : (
          <div className="AccountLogin">
            <h1>
              {isAmbassador ? (
                <>
                  Ambassador <br />
                  <em>Log in</em>
                </>
              ) : (
                translate('login.page_title')
              )}
            </h1>
            <div>
              <AuthLoginForm
                onLogin={() => {
                  setLastLogin(Date.now().toString());
                }}
              />
            </div>
            {isAmbassador && (
              <ShowOnScroll>
                <div className="AccountLogin__ambassador">
                  <div className="AccountLogin__ambassadorTitle">
                    <ReactMarkdown>
                      {data.contentfulAmbassadorPortal.login.title}
                    </ReactMarkdown>
                  </div>
                  <div className="AccountLogin__ambassadorContent">
                    {data.contentfulAmbassadorPortal.login.values.map(
                      ({ title, text }) => (
                        <div
                          key={title}
                          className="AccountLogin__ambassadorValue"
                        >
                          <h3>{title}</h3>
                          <p>{text}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </ShowOnScroll>
            )}
          </div>
        )
      }
    />
  );
};

export default AccountLoginPage;

interface AccountLoginPageProps {
  path: string;
}
