import styled from 'styled-components';
// AmbassadorOrders__content__heading
export const StyledAmbassadorOrders = styled.div`
  margin-bottom: 10rem;

  .AmbassadorOrders {
    &__header h1 {
      font-style: normal;
      font-weight: 300;
      font-size: 60px;
      line-height: 58px;
      letter-spacing: -0.01em;
      color: #131313;
      text-align: center;
      em {
        font-size: 1em;
        line-height: 96%;
      }
    }
    &__headerActions {
      display: flex;
      justify-content: center;
      gap: 16px;

      a {
        font-weight: 500;

        @media (max-width: 540px) {
          padding: 8px 12px;
        }
      }
    }
    &__content__heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
      gap: 24px;

      h2 {
        margin: 0;
      }
      a {
        font-weight: 500;
      }

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__orders {
      margin: 25px 0;
      width: 100%;
      border-collapse: collapse;

      .with-tooltip {
        text-overflow: ellipsis;
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
      }

      th {
        font-family: var(--primaryFont);
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #1f1f1f;
        text-align: left;
        &:first-child,
        &:last-child {
          text-align: center;
        }
      }
      tr {
        border-bottom: 1px solid rgba(19, 19, 19, 0.2);
        &.open td,
        &.open {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
      td,
      th {
        padding: 25px 0;
        border-bottom: 1px solid rgba(19, 19, 19, 0.2);
      }
      td {
        font-family: var(--primaryFont);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: var(--black);
        text-align: left;
        &:first-child,
        &:last-child {
          text-align: center;
        }
        a {
          color: ${props => props.theme.colors.orange};
        }
      }

      .line_items {
        td {
          text-align: left;
        }
        &__content {
          border: 1px solid rgba(19, 19, 19, 0.2);
          border-radius: 12px;
          padding-inline: 20px;
        }

        &__item {
          border-bottom: 1px solid rgba(19, 19, 19, 0.2);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 16px;
          padding-block: 20px;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .cart-btn {
        color: ${props => props.theme.colors.orange};
        text-decoration: underline;
        &:after {
          display: none;
        }
      }
    }
    &__pagination {
      ul,
      li {
        list-style: none;
      }
      ul {
        display: flex;
        justify-content: center;
      }
      li {
        margin: 5px;
      }
      button {
        appearance: none;
        background: none;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 3px;

        font-family: var(--primaryFont);
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */
        letter-spacing: 0.1em;
        color: #131313;
        position: relative;
        &:after {
          left: 0;
          bottom: 0;
          content: '';
          position: absolute;
          transition: width 0.3s ease-in-out;
          height: 1px;
          background: currentColor;
        }
        &:focus,
        &:hover,
        &.current {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
`;
