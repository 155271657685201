import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import { FC } from 'react';
import { Cta } from '~/components/Generic/Cta/Cta';
import { Any } from '../../../../../types/types';

const BillingSummary: FC<BillingSummaryProps> = ({
  subscription,
  openForm,
}) => {
  const translate = useTranslate();

  return (
    <div>
      <p>{subscription.billAddress.address1}</p>
      <p>{subscription.billAddress.address2}</p>

      <p>{subscription.billAddress.city}, </p>
      <p> {subscription.billAddress.state.name}</p>

      {/* <p>
        <p>
          <strong>{translate('subscriptions.payment_method')} </strong>
          XXXX XXXX XXXX {subscription.payment.source.lastDigits}
        </p>
      </p> */}

      <Cta variant="secondary" onClick={openForm} type="submit">
        {translate('subscriptions.edit')}
      </Cta>
    </div>
  );
};

export default BillingSummary;

interface BillingSummaryProps {
  subscription: Any;
  openForm: () => void;
}
