import { FC, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useAuth, useUser } from '@chordcommerce/gatsby-theme-autonomy';
import Loader from '~/components/Loader/Loader';
import { Cta } from '~/components/Generic/Cta/Cta';

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  ...props
}) => {
  const { isLoggedIn, isFetching } = useAuth();
  const { user } = useUser();
  const [hasRole, setHasRole] = useState(false);

  useEffect(() => {
    if (!isLoggedIn && !isFetching) {
      navigate('/account/login');
    }
  }, [isLoggedIn, isFetching]);

  useEffect(() => {
    if (user?.data?.email && props.requireRole) {
      const userHasRole = ((user.data as any).roles || []).includes(
        props.requireRole
      );
      setHasRole(userHasRole);
      if (!userHasRole) {
        navigate(props.href || '/account/login', { replace: true });
      }
    } else {
      setHasRole(true);
    }
  }, [isLoggedIn, user]);

  if (!isLoggedIn && isFetching) {
    return (
      <div>
        <div>
          <div>
            <Loader text="Loading..." />
          </div>
        </div>
      </div>
    );
  }

  if (!isLoggedIn && !isFetching) {
    return null;
  }

  if (!hasRole) {
    return null;
  }

  return (
    <>
      <Component {...props} />
      {props.showBackButton && (
        <div className="PrivateRoute__back">
          <Cta variant="secondary" href="/account">
            Back to account
          </Cta>
        </div>
      )}
    </>
  );
};

export default PrivateRoute;

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  path: string;
  showBackButton?: boolean;
  href?: string;
  requireRole?: string;
}
