import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { StyledArticleCard } from './ArticleCard.style';
import { Article } from '../../types/types';

export const ArticleCard: React.FC<ArticleCardProps> = ({
  article,
  root = 'blog/',
}) => {
  const { slug, thumbnailImage, title, shortDescription, publishDate } =
    article;
  const formattedDate = useMemo(
    () =>
      new Date(publishDate).toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
    [publishDate]
  );
  return (
    <StyledArticleCard>
      <Link to={`/${root}${slug}`}>
        {thumbnailImage && (
          <GatsbyImage
            className="article-card--thumbnail"
            image={thumbnailImage.gatsbyImageData}
            alt={title}
          />
        )}
        <div className="article-card--cardTitle">{title}</div>
        {shortDescription && (
          <div className="article-card--cardDesc">{shortDescription}</div>
        )}
        {publishDate && (
          <div className="article-card--cardDate">{formattedDate}</div>
        )}
      </Link>
    </StyledArticleCard>
  );
};

interface ArticleCardProps {
  article: Article;
  root?: string;
}
