import { useUser } from '@chordcommerce/gatsby-theme-autonomy';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { paymentTypesMap, shippingStatusesMap } from '~/types/constants';
import { formatPhoneNumber } from '~/utils/tools';
import {
  StyledAmbassadorMobileOrders,
  StyledMobileOrders,
} from './AmbassadorOrdersMobile.style';

export const AmbassadorOrdersMobile: React.FC<Props> = ({ orders }) => {
  const [openIdx, setOpenIdx] = useState(-1);
  const { user } = useUser();
  return (
    <StyledAmbassadorMobileOrders>
      <StyledMobileOrders className="orders">
        {!orders.length
          ? null
          : orders.map((order: any, i: number) => {
              const shipments = (order.shipments || []).filter(
                (ship: any) => ship.tracking
              );
              const cartOpen = openIdx === i;
              return (
                <>
                  <div
                    className={[
                      'order__grid',
                      cartOpen ? 'order__grid--open' : '',
                    ].join(' ')}
                  >
                    <div className="order__grid__item">
                      <span className="order__grid__item__title">
                        Order Date
                      </span>
                      <span>
                        {format(new Date(order.completed_at), 'MM/dd/yyyy')}
                      </span>
                    </div>
                    <div className="order__grid__item">
                      <span className="order__grid__item__title">Order ID</span>
                      {`${order.number}`.split('-').pop()}
                    </div>
                    <div className="order__grid__item">
                      <span className="order__grid__item__title">Tracking</span>
                      {shipments.map((s: any) => {
                        if (s.tracking_url) {
                          return (
                            <a
                              href={s.tracking_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Track
                            </a>
                          );
                        }

                        return (
                          <div>
                            {shippingStatusesMap[order.shipment_state] ||
                              order.shipment_state}
                          </div>
                        );
                      })}
                    </div>
                    <div className="order__grid__item">
                      <span className="order__grid__item__title">Type</span>
                      <span>
                        {order.order_type}
                      </span>
                    </div>
                    <div className="order__grid__item">
                      <span className="order__grid__item__title">Status</span>
                      <span>
                        {paymentTypesMap[order.payment_state] ||
                          order.payment_state}
                      </span>
                    </div>
                    <div className="order__grid__item">
                      <span className="order__grid__item__title">Name</span>
                      <span>{order.ship_address?.name}</span>
                    </div>

                    <div className="order__grid__item">
                      <span className="order__grid__item__title">Email</span>
                      <span>{order.email}</span>
                    </div>

                    <div className="order__grid__item">
                      <span className="order__grid__item__title">Address</span>
                      <span>{[order.ship_address.address1, order.ship_address.address2, order.ship_address.city, order.ship_address.state_text, order.ship_address.zipcode].filter(Boolean).join(', ')}</span>
                    </div>
                    <div className="order__grid__item">
                      <span className="order__grid__item__title">Phone #</span>
                      <span>{order.ship_address.phone ? formatPhoneNumber(order.ship_address.phone) : ''}</span>
                    </div>
                    <div className="order__grid__item">
                      <span className="order__grid__item__title">QV</span>
                      <span>${(1 * order.qualifyingVolume).toFixed(0)}</span>
                    </div>
                    <div className="order__grid__item">
                      <span className="order__grid__item__title">Cart</span>
                      <button
                        className={[
                          'cart-btn',
                          i === openIdx ? 'cart-btn--open' : '',
                        ].join(' ')}
                        onClick={
                          cartOpen ? () => setOpenIdx(-1) : () => setOpenIdx(i)
                        }
                      >
                        {cartOpen ? 'Close Cart' : 'View more'}
                      </button>
                    </div>
                    {cartOpen && (
                      <div className="cart-items">
                        {order.simplified_line_items.map((item: any) => (
                          <div className="cart__content">
                            <span>{item.quantity}x</span>
                            <span>{item.text}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              );
            })}
      </StyledMobileOrders>
    </StyledAmbassadorMobileOrders>
  );
};

interface Props {
  orders: any[];
}

interface OrderProps {
  order: any;
  openIdx: number;
  setOpenIdx: (idx: number) => void;
}
