import React, { Fragment } from 'react';
import RedShape from '~/assets/images/shape-2.svg';
import Shape2 from '~/assets/images/shapes/shape2.svg';
import Shape3 from '~/assets/images/shapes/shape3.svg';
import Shape4 from '~/assets/images/shapes/shape4.svg';
import Shape5 from '~/assets/images/shapes/shape5.svg';
import { Cta } from '../Generic/Cta/Cta';
import { StyledAmbassadorPLPCard } from './AmbassadorPLPCard.style';

import { useTheme } from 'styled-components';
import { useCartContext } from '../CartContext/CartContext';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';

export const AmbassadorPLPCard: React.FC<AmbassadorPLPCardProps> = ({}) => {
  const theme = useTheme();
  const { enrollAmbassador } = useCartContext();
  const SVG = [
    <Shape5 fill={theme.colors.white} />,
    <Shape4 fill={theme.colors.green} />,
    <Shape2 fill={theme.colors.white} />,
    <Shape3 fill={theme.colors.orange} />,
    <RedShape />,
  ];

  return (
    <StyledAmbassadorPLPCard>
      <div className="content">
        <ShowOnScroll>
          <h3>Become a Commons Ambassador</h3>
        </ShowOnScroll>
        <ShowOnScroll delay={200}>
          <p>Start your business for only $39.99</p>
        </ShowOnScroll>
        <ShowOnScroll delay={200}>
        <Cta href="#" onClick={enrollAmbassador} variant="primary">
          Add to Cart
        </Cta>
        </ShowOnScroll>
        <ShowOnScroll delay={400}>
          <Cta href="/join" variant="secondary">
            Learn More
          </Cta>
        </ShowOnScroll>
      </div>
      <div className="shapes">
        {SVG.map((s, i) => {
          const animationClass = i === SVG.length - 1 ? 'spring' : 'scale';
          return (
            <ShowOnScroll
              passive
              delay={200 + i * 200}
              key={`shape_${i}`}
              className={`shapes__container ${animationClass}`}
            >
              {s}
            </ShowOnScroll>
          );
        })}
      </div>
    </StyledAmbassadorPLPCard>
  );
};

interface AmbassadorPLPCardProps {}
