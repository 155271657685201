import styled from 'styled-components';
import { desktopHover } from "~/utils/tools";
export const StyledAmbassadorResources = styled.div`
.AmbassadorResources__actions{
  text-align: center;
  margin: 1rem auto 0 auto;

  font-family: 'Founders Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: var(--black);
  a {
    color: inherit;
  }
}

.AmbassadorResources__carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  transform: translateX(-50%);
  position: relative;
  left: 50%;
  background: var(--gray);
  z-index: 3;
  padding: 8rem 0;
  &Title {
    text-align: left;
    display: flex;
    width: 90%;
    max-width: 1152px;
    margin: 0 auto 50px;
    align-items: center;
    justify-content: space-between;
    h2 {
      margin: 0;
    }
  }
  &Item {
    width: 100%;
    max-width: 330px;
  }
  .swiper-scrollbar {
    width: 90%;
    max-width: 1152px;
    margin: 0 auto;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    background: none;
    &:before {
      background: var(--black);
      opacity: 0.1;
      content: "";
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      display: block;
      position: relative;
      top: 1px;
    }
  }
  &Content {
    width: 100%;
  }
}
.AmbassadorResources__resources {
  padding :6rem 0;
  &Title h2 {
    text-align: center;
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 40px;
    color: var(--black);
  }
  &Content {
    margin-top: calc(6rem - 40px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }

    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;

    a {
      color: var(--green);
      font-weight: 400;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    h1,h2,h3 {
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      color: #1F1F1F;
      margin-top: 40px;
    }
  }
}
`