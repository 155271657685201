import { useTranslate, useUser } from "@chordcommerce/gatsby-theme-autonomy";
import React, { useEffect, useState } from "react";

import api from "@chordcommerce/gatsby-theme-autonomy/src/services/api/index.js"
import axiosClient from '@chordcommerce/gatsby-theme-autonomy/src/services/api/axios-client'
import Loader from "~/components/Loader/Loader";

async function getOrders() {
  const authToken = await api.getAuthorizationHeader()
  const url = `/api/orders/mine?per_page=250&q[state_eq]=complete`
  const config = { headers: {} }
  if (authToken) (config.headers as any).Authorization = authToken
  const response = await axiosClient.get(url, config)
  return response.data
}

export const Orders = () => {
  const translate = useTranslate()
  const [orders, setOrders] = useState<null|any[]>(null)
  const [loading, setLoading] = useState(true)
  const [fetchedOrders, setFetchedOrders] = useState(false)
  const { user } = useUser();

  useEffect(() => {
    if (!user || fetchedOrders) {
      return;
    }
    const fetchOrders = async () => {
      const myOrders = await getOrders()
      setOrders(myOrders?.orders || [])
      setLoading(false)
    }
    setFetchedOrders(true)
    fetchOrders();
  }, [user, fetchedOrders])
  
  const paymentTypesMap: any = {
    paid: "Paid"
  }
  const shippingStatusesMap: any = {
    "ready": "Ready to Ship",
    "shipped": "Fulfilled",
    "backorder": "Back Order"
  } 

  return (
    <div className="AccountOrders">
      <div>
        <h1>
          {translate('orders.page_title')}
        </h1>
        {loading && <Loader text="Getting your latest orders..." />}
        {!loading && !orders?.length && <p>You have not placed any order yet.</p>}
        {!loading && orders?.length && (
          <table className="AccountOrders__orders">
            <thead>
              <tr>
                <th>Order No.</th>
                <th>Date</th>
                <th>Payment</th>
                <th>Status</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((order: any) => (
                <tr key={order.id}>
                  <td><a href={`/account/order?order=${order.number}&token=${order.token}`}>{order.number}</a></td>
                  <td>{new Date(order.completedAt).toLocaleDateString("en-US", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric"
                  })}</td>
                  <td>{paymentTypesMap[order.paymentState] || order.paymentState}</td>
                  <td>{shippingStatusesMap[order.shipmentState] || order.shipmentState}</td>
                  <td>{order.displayTotal}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}