// eslint-disable-next-line no-unused-vars
import React, { FC, useRef, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import {
  useSubscription,
  useTranslate,
} from '@chordcommerce/gatsby-theme-autonomy';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Any } from '../../../../../types/types';
import { Cta } from '~/components/Generic/Cta/Cta';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

const inputStyle = {
  maxWidth: '350px',
  flex: 4,
};

const flexStyle = {
  flexDirection: ['column', 'row'],
  justifyContent: 'space-between',
  margin: '10px',
  alignItems: ['left', 'center'],
};

const textStyle = {
  textAlign: 'left',
  marginRight: '3rem',
  flex: 1,
};

const SubscriptionDetailsAddressForm: FC<
  SubscriptionDetailsAddressFormProps
> = ({ addressType, subscription, close }) => {
  const translate = useTranslate();
  const formRef = useRef();
  // const stripe = useStripe();
  // const elements = useElements();

  const { updateSubscription } = useSubscription(subscription.id);

  const address = subscription[addressType];

  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: address.name,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      state_name: address.state.abbr,
      country_iso: address.country.iso,
      zipcode: address.zipcode,
    },
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isBilling = addressType === 'billAddress';

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    setError(null);
    console.log({data})

    try {
      const paramsPrefix = `${addressType}Attributes`;
      let payload: any = { [paramsPrefix]: {...data, paymentSourceAttributes: undefined}, paymentSourceAttributes: data?.paymentSourceAttributes || undefined };

      if (isBilling) {

        if (error) {
          setError(error.message);
          setLoading(false);
          return;
        }
      }

      await updateSubscription(payload);
      setTimeout(() => close(), 1);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  };

  return (
    <form ref={formRef} onSubmit={isBilling ? null :  handleSubmit(onSubmit)}>
      <div>
        <p>{translate('address.name')}</p>
        <input ref={register({ maxLength: 80 })} name="name" />
      </div>

      <div>
        <p>{translate('address.address1')}</p>
        <input
          ref={register({ required: true, maxLength: 80 })}
          name="address1"
        />
      </div>

      <div>
        <p>{translate('address.address2')}</p>
        <input ref={register} name="address2" />
      </div>

      <div>
        <p>{translate('address.city')}</p>
        <input ref={register({ required: true, maxLength: 80 })} name="city" />
      </div>

      <div>
        <p>{translate('address.state')}</p>
        <input
          ref={register({ required: true, maxLength: 80 })}
          name="state_name"
        />
      </div>

      <div>
        <p>{translate('address.country')}</p>
        <input
          ref={register({ required: true, maxLength: 80 })}
          hidden
          name="country_iso"
        />
      </div>

      <div>
        <p>{translate('address.zip')}</p>
        <input
          ref={register({ required: true, maxLength: 80 })}
          name="zipcode"
        />
      </div>

      {isBilling && (
        <>
          <div>
            <p>Card Info</p>
          </div>
          <PaymentForm
            applicationId={process.env.GATSBY_SQUARE_APPLICATION_ID}
            /**
              * Invoked when payment form receives the result of a tokenize generation request.
              * The result will be a valid credit card or wallet token, or an error.
              */
            
            cardTokenizeResponseReceived={(token, buyer) => {
              if (token?.token) {
                (async () => {

                    const paymentAttributes:any = {
                      payment_method_type: 'square',
                      nonce: token.token
                    };
                    const formData = new FormData(formRef?.current);
                    const formObject:any = {}
                    formData.forEach((value: any, key: string) => (formObject[key] = value));
                    const submitData = { paymentSourceAttributes: paymentAttributes, ...formObject };
                    console.log({submitData})
                   onSubmit(submitData);
                })()
              }
            }}
            locationId={process.env.GATSBY_SQUARE_LOCATION_ID}
            
          >
            <CreditCard
              postalCode={subscription?.[addressType]?.zipcode ? `${subscription?.[addressType]?.zipcode}` : undefined}
            >Update</CreditCard>
          </PaymentForm>
        </>
      )}

      {error && <p>{error.message}</p>}

      <div>
        {!isBilling && <Cta variant="primary" type="submit">
          {loading && <p>Loading...</p>}
          {!loading && 'Update'}
        </Cta>}<br />
        <Cta variant="secondary" onClick={close}>{translate('address.cancel')}</Cta>
      </div>
    </form>
  );
};

export default SubscriptionDetailsAddressForm;

interface SubscriptionDetailsAddressFormProps {
  addressType: Any;
  subscription: Any;
  close: Any;
}
