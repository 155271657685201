import ChevronIcon from '~/assets/images/icons/chevron.svg';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';
import {
  StyledDownlineCTARow,
  StyledDownlineDesktop,
  StyledDownlineDesktopRow,
} from '~/components/Account/AmbassadorPortal/Downline/DownlineDesktop.styled';
import theme from '~/styles/theme';
import { Tooltip, TooltipProps } from '@mantine/core';
import { format } from 'date-fns';
import { Cta } from '~/components/Generic/Cta/Cta';
import { RANKS } from '~/types/constants';

// TODOs Make export button work
const tooltipProps: Partial<TooltipProps> = {
  withArrow: true,
  className: 'tooltip',
  style: { textTransform: 'none', fontFamily: 'var(--primaryFont)' },
  color: theme.colors.green,
  position: 'top-start',
  transition: 'pop',
};

const columnTitles: { title: string; tooltip?: string }[] = [
  {
    title: 'Level',
  },
  {
    title: 'Ambassador Name',
  },
  {
    title: 'Enrollment Date',
  },
  {
    title: 'Rank',
  },
  {
    title: 'MSR',
    tooltip: 'Maximum Sales Rule',
  },
  {
    title: 'PV',
    tooltip: 'Personal Volume',
  },
  {
    title: 'TV',
    tooltip: 'Team Volume',
  },
  {
    title: 'DV',
    tooltip: 'Downline Volume',
  },
  {
    title: 'LPV',
    tooltip: 'Lifetime Personal Volume',
  },
];

export const DownlineDesktop: React.FC<DownlineGridProps> = ({
  nodes,
  onOpenDetails,
  downloadReport
}) => {
  return (
    <StyledDownlineDesktop>
      <div className="downline__grid downline__grid__header">
        {columnTitles.map(({ title, tooltip }) => {
          if (tooltip) {
            return (
              <Tooltip {...tooltipProps} label={tooltip}>
                <span>{title}</span>
              </Tooltip>
            );
          }

          return <span>{title}</span>;
        })}
      </div>
      {nodes.map(node => (
        <DownlineGridRow
          ambassador={node}
          key={node.id}
          onOpenDetails={onOpenDetails}
          downloadReport={downloadReport}
        />
      ))}
    </StyledDownlineDesktop>
  );
};

const DownlineGridRow: React.FC<DownlineGridRowProps> = ({
  ambassador,
  onOpenDetails,
  downloadReport
}) => {
  const [open, { toggle }] = useDisclosure(false);
  const { firstName, lastName, level, children, joinDate } = ambassador;
  const hasChildren = children.length > 0;
  const isMax = level >= 7;
  const { msr, name: rank } =
    RANKS[`${ambassador.payRankID}` as keyof typeof RANKS];

  return (
    <>
      <StyledDownlineDesktopRow
        className="downline__grid downline__grid__body"
        childCount={children.length}
        level={level}
      >
        <span className="level">
          <button
            className={[
              'level__button',
              open ? 'level__button--open' : '',
              hasChildren ? 'level__button--visible' : '',
            ].join(' ')}
            onClick={toggle}
            disabled={isMax}
            aria-label="Toggle children"
          >
            <ChevronIcon />
          </button>
          <span className="level__indicator">{level}</span>
        </span>
        <span className="ambassador">
          <span className="ambassador__name">
            {firstName} {lastName}
          </span>
          {[0, 1].includes(level) && (
            <button
              type="button"
              className="order-detail-btn"
              onClick={() => onOpenDetails(ambassador)}
            >
              View Order Details
            </button>
          )}
        </span>
        <span>{joinDate ? format(new Date(joinDate), 'MM/dd/yyyy') : ''}</span>
        <span>{rank || 'N/A'}</span>
        <span>{msr || 'N/A'}</span>
        <span>${Math.round(ambassador.volume2 as number).toLocaleString('en-US')}</span>
        <span>${Math.round(ambassador.volume5 - ambassador.volume2).toLocaleString('en-US')}</span>
        <span>${Math.round(ambassador.volume5 as number).toLocaleString('en-US')}</span>
        <span>${Math.round(ambassador.volume9 as number).toLocaleString('en-US')}</span>
      </StyledDownlineDesktopRow>
      {isMax && (
        <StyledDownlineCTARow>
          <p>For Levels 8 and Beyond, download the full report.</p>
          <Cta onClick={() =>  downloadReport?.()}>Download full report</Cta>
        </StyledDownlineCTARow>
      )}
      {open &&
        !isMax &&
        children.map((child: any) => {
          return (
            <DownlineGridRow
              ambassador={child}
              downloadReport={downloadReport}
              key={child.id}
              onOpenDetails={onOpenDetails}
            />
          );
        })}
    </>
  );
};

interface DownlineGridProps {
  nodes: any[];
  onOpenDetails: (ambassador: any) => void;
  downloadReport?: () => void;
}

interface DownlineGridRowProps {
  ambassador: any;
  onOpenDetails: (ambassador: any) => void;
  downloadReport?: () => void;
}
