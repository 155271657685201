import SubscriptionCard from '~/components/Account/Subscription/SubscriptionCard';
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import { Chord, OMS, Analytics } from '@chordcommerce/chord-js-core/dist/types'
import { Any, Subscription } from '../../../../types/types';

const SubscriptionList: React.FC<SubscriptionListProps> = ({
  subscriptions,
}) => {
  if (!subscriptions || subscriptions.length === 0) return <EmptyList />;

  const sortedSubscriptions = subscriptions.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  return (
    <div className="AccountSubscriptionList">
      {sortedSubscriptions.map((subscription, index) => (
        <SubscriptionCard key={index} subscription={subscription} />
      ))}
    </div>
  );
};

const EmptyList = () => {
  const translate = useTranslate();

  return <h2>{translate('subscriptions.no_subscriptions_yet')}</h2>;
};

export default SubscriptionList;

interface SubscriptionListProps {
  subscriptions: Subscription[];
}
