import styled from "styled-components";
import { desktopHover, pxtorem } from "~/utils/tools";

export const StyledAmbassadorPerformance = styled.div`
  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 58px;
    letter-spacing: -0.01em;
    color: #131313;
    text-align: center;
    em {
      font-size: 1em;
      line-height: 96%;
    }
  }
  .AmbassadorPerformance__counters {
    margin-bottom: 36px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    @media (max-width: 1024px) {
      display: block;
      >* {
        margin-top: 1rem;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .AmbassadorPerformance__downline {
    display: flex;
    justify-content: space-between;
    margin-bottom: 64px;
    /* @media (max-width: 1024px) {
      display: block;
    } */
    h2 {
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 44px;
      color: #131313;
      margin-bottom: 4px;
    }

    .export-btn {
      font: 500 ${pxtorem(20)} / 1.2 var(--primaryFont);
      max-height: 56px;
    }

    &__month {
      display: flex;
      align-items: center;
      gap: 8px;
      .prev svg {
        transform: scale(-1);
      }
      button {
        cursor: pointer;
        background: none;
        border: none;
        width: 28px;
        height: 28px;
        padding: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s var(--cubic-ease);

        svg path {
          fill: ${({ theme }) => theme.colors.black};
        }

        &:disabled {
          display: none;
        }

        ${({theme}) => desktopHover(`
          background: ${theme.colors.lime};
        `)}
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: ${pxtorem(40)};

      &__month {
        margin-bottom: ${pxtorem(24)};
      } 
    }
  }
`