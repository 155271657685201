import styled from "styled-components";

export const StyledAmbassadorHeader = styled.div`
  display: grid;
  grid-template-columns: 53fr 47fr;
  background: var(--lime);
  overflow: hidden;
  border-radius:12px;
  margin-bottom: 36px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  h1 {
    font-size: 3rem;
    em {
      font-size: 1em;
    }
    @media (max-width: 1024px) {
      font-size: 2rem;
    }
  }

  .AmbassadorHeader__image {
    @media (max-width: 1024px) {
      display: none;
    }
    position: relative;
    &:after {
      padding-top: 80%;
      content: "";
      display: block;
    }
    .gatsby-image-wrapper {
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .AmbassadorHeader__content {
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    @media (max-width: 1024px) {
      padding: 2rem 1rem;
      display: block;
      text-align: center;
    }
  }
  .AmbassadorHeader__links {
    --links-min-width: 282px;

    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    grid-gap: 1rem;
    button, a {
      margin: 0 auto 0;
      min-width: var(--links-min-width);
      @media (max-width: 1024px) {
        margin: 1rem auto 0 auto;
      }
    }
    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
    .AmbassadorHeader__copy {
      min-width: 0;
    }
  }
  .AmbassadorHeader__facts {
    display: flex;
    flex-wrap: wrap;
    > div {
      margin-right: 2rem;
    }
    svg {
      display: inline-block;
      vertical-align: top;
    }
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .AmbassadorHeader__copiedNotification {
    position: absolute;
    transform: translateY(0%) translateX(-50%);
    transition: transform 0.3s ease, opacity 0.3s ease;
    z-index: 2;
    padding: 0.35rem;
    font-weight: normal;
    background: var(--green);
    color: var(--white);
    border-radius: 12px;
    top: 0;
    left: 50%;
    width: var(--links-min-width);
    pointer-events: none;
    margin-top: -10px;
    opacity: 0;
    &.shown {
      transform: translateY(-100%) translateX(-50%);
      opacity: 1;
    }
    &:after {
      content: "";
      display: block;
      width:0;
      height:0;
      position: absolute;
      top: 100%;
      margin-top: -1px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      
      border-top: 8px solid var(--green);
    }
  }
  .AmbassadorHeader__links {
    position: relative;
    button {
      position: relative;
    }
  }
`;