import {
  useTranslate,
  useSubscriptions,
} from '@chordcommerce/gatsby-theme-autonomy';
import SubscriptionList from '~/components/Account/Subscription/List';

const AccountSubscriptionPage = () => {
  const translate = useTranslate();
  const subscriptions = useSubscriptions();

  return (
    <div className="AccountSubscriptions">
      <div>
        <h1>{translate('subscriptions.page_title')}</h1>
        <SubscriptionList subscriptions={subscriptions} />
      </div>
    </div>
  );
};

export default AccountSubscriptionPage;
