import { useDisclosure } from '@mantine/hooks';
import { format } from 'date-fns';
import React from 'react';
import Chevron from '~/assets/images/icons/chevron.svg';
import {
  StyledDownlineData,
  StyledDownlineItem,
} from '~/components/Account/AmbassadorPortal/Downline/MobileDownline.styled';
import { RANKS } from '~/types/constants';

export const DownlineMobile: React.FC<DownlineMobileProps> = ({
  nodes,
  onOpenDetails,
}) => {
  return (
    <>
      {nodes.map((node, i: number) => (
        <DownlineItem
          ambassador={node}
          onOpenDetails={onOpenDetails}
          totalNodes={nodes.length}
          nodeIdx={i + 1}
          key={node.customerID}
        />
      ))}
    </>
  );
};

const DownlineItem: React.FC<DownlineItemProps> = ({
  ambassador,
  onOpenDetails,
  nodeIdx,
  totalNodes,
  downloadReport
}) => {
  const [open, { toggle }] = useDisclosure(false);
  const { firstName, lastName, level, children, joinDate, payRankID } =
    ambassador;
  const hasChildren = children.length > 0;
  const isMax = level >= 3;
  return (
    <>
      <StyledDownlineItem
        level={level}
        hasChildren={hasChildren}
        isExpanded={open}
        totalNodes={totalNodes}
        nodeIdx={nodeIdx}
        onClick={toggle}
      >
        <div className="downline__item__header">
          <div className="downline__item__header__left">
            <div className="downline__item__header__level">{level}</div>
            <div className="downline__item__header__name">
              {firstName} {lastName}
            </div>
            <span className="downline__item__header__childcount">
              {children.length}
            </span>
          </div>

          <div className="downline__item__header__right">
            <button
              type="button"
              className={['expand-btn', open ? 'expand-btn--open' : ''].join(
                ' '
              )}
              disabled={isMax}
              onClick={toggle}
            >
              <Chevron />
            </button>
          </div>
        </div>
      </StyledDownlineItem>
      {open && (
        <StyledDownlineData level={level} hasChildren={hasChildren}>
          <div className="stats" >
            <div className="stats__row__head">
              <div className="date">
                <p className="label">Enrollment Date</p>
                <span>{joinDate ? format(new Date(joinDate), 'MM/dd/yyyy') : ''}</span>
              </div>
              <div className="rank">
                <p className="label">Rank</p>
                <span>{RANKS[`${payRankID}` as keyof typeof RANKS].name}</span>
              </div>
            </div>
            <div className="stats__row__stats">
              <div className="msr">
                <p className="label">MSR</p>
                <span>{RANKS[`${payRankID}` as keyof typeof RANKS].msr || 'N/A'}</span>
              </div>
              <div className="pv">
                <p className="label">PV</p>
                <span>${Math.round(ambassador.volume2 as number).toLocaleString('en-US')}</span>
              </div>
              <div className="tv">
                <p className="label">TV</p>
                <span>${Math.round(ambassador.volume5 - ambassador.volume2).toLocaleString('en-US')}</span>
              </div>

              <div className="dv">
                <p className="label">DV</p>
                <span>${Math.round(ambassador.volume5 as number).toLocaleString('en-US')}</span>
              </div>

              <div className="lpv">
                <p className="label">LPV</p>
                <span>${Math.round(ambassador.volume9 as number).toLocaleString('en-US')}</span>
              </div>
            </div>
            {[0, 1].includes(level) && (
              <button
                type="button"
                className="details-btn"
                onClick={() => onOpenDetails(ambassador)}
              >
                View Order Details
              </button>
            )}
          </div>
          {children.map((child: any) => (
            <DownlineItem ambassador={child} onOpenDetails={onOpenDetails} downloadReport={downloadReport} />
          ))}
        </StyledDownlineData>
      )}
    </>
  );
};

interface DownlineMobileProps {
  nodes: any[];
  onOpenDetails: (ambassador: any) => void;
}

interface DownlineItemProps {
  ambassador: any;
  downloadReport?: () => void;
  onOpenDetails: (ambassador: any) => void;
  nodeIdx?: number;
  totalNodes?: number;
}
