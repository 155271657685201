import styled from "styled-components";

export const StyledNumberTicker = styled.div`

  background: var(--lime);
  padding: 2.5rem;
  color: var(--green);
  border-radius: 10px;
  h3 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #1F1F1F;
    margin: 0 0 1rem 0;
  }

  h4 {

    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #1F1F1F;
    margin: 0 0 0 0;
  }

  .NumberTickerValue {
    padding-top: 3rem;
    margin-top: auto;

    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 300;
    font-size: 120px;
    line-height: 1;
    letter-spacing: -0.01em;
    color: #34563B;

    @media screen and (max-width: 768px) {
      font-size: 60px;
    }
  }
`;