import { selectors } from '@chordcommerce/gatsby-theme-autonomy'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useProductMainImage = ({ slug, sku }: {slug: string; sku: string;}) => {
  const { getAllProducts } = selectors
  const products: ChordProduct[] = useSelector(getAllProducts)

  const [image, setImage] = useState(null)

  useEffect(() => {
    const product = products.find((p) => p.slug === slug)

    if (!product) return

    const isKit = sku.includes('|')

    const image = isKit
      ? product.mainImage
      : product.variants.find((v: any) => v.sku === sku)?.mainImage

    if (image) setImage(image)
  }, [products, sku, slug])

  return { image }
}

export default useProductMainImage
