import React, { useState, useMemo, useLayoutEffect, useRef } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { navigate } from 'gatsby';
import { StytchLogin } from '@stytch/nextjs';
import { Products, OAuthProviders } from '@stytch/vanilla-js';
import { useAuth, useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import { Cta, CtaProps } from '~/components/Generic/Cta/Cta';

const AuthLoginForm: React.FC<AuthLoginFormProps> = ({
  path = '/account',
  variant = undefined,
  onLogin,
}) => {
  const translate = useTranslate();
  const { register, handleSubmit } = useForm();
  const { login } = useAuth();
  const [error, setError] = useState(null);

  const handleLogin = async ({ email }: FieldValues) => {
    setError(null);
    try {
      onLogin?.();
      await login({
        email,
        showUI: true,
        redirectURI: `${window.location.origin}${path}`,
      });
    } catch (error) {
      setError(error);
    }
    window.location.assign(`${path}`);
  };

  return (
    <form className="AccountLogin__form" onSubmit={handleSubmit(handleLogin)}>
      <input placeholder="E-mail" ref={register()} name="email" />
      <Cta {...{ type: 'submit' }} variant={variant}>
        {translate('login.sign_in')}
      </Cta>
      {error && <p className="error">{error.message}</p>}
    </form>
  );
};

type StytchProps = Parameters<typeof StytchLogin>[0];

const StytchLoginForm: React.FC<AuthLoginFormProps> = ({
  path = '/account',
  variant = undefined,
  onLogin,
}) => {
  const stytchElmRef = useRef<HTMLDivElement>(null);
  const stytchConfig = useMemo<StytchProps>(() => {
    let baseUrl = '';

    if (typeof window !== 'undefined') {
      baseUrl = window.location.origin;
    }

    return {
      type: 'email',
      config: {
        products: [Products.emailMagicLinks],
        sessionOptions: {
          sessionDurationMinutes: 129600,
        },
        emailMagicLinksOptions: {
          loginRedirectURL: `${baseUrl}/authenticate`,
          loginExpirationMinutes: 30,
          signupRedirectURL: `${baseUrl}/authenticate`,
          signupExpirationMinutes: 30,
        },
      },
      styles: {
        hideHeaderText: true,
        width: '100%',
        primaryColor: '#215732',
        fontFamily: 'var(--primaryFont)',
      },
      callbacks: {
        onEvent: message => console.log(message),
        onError: message => console.log(message),
      },
    };
  }, [onLogin, path]);

  // We must force the input to be type="email". Unfortunately, Stytch's
  // component does not allow for this to be done easily so we must do it
  // manually in an effect. A ticket has been opened to make this easier via
  // props, but for now, we must do this manually so that user's are presented
  // the correct keyboard on mobile.
  useLayoutEffect(() => {
    if (stytchElmRef.current) {
      const stytchUiElm = stytchElmRef.current.querySelector('stytch-ui');

      if (!stytchUiElm || !stytchUiElm.shadowRoot) {
        return;
      }

      const inputElm = stytchUiElm.shadowRoot.querySelector<HTMLInputElement>(
        'input[name="email"]'
      );

      if (!inputElm) {
        return;
      }

      console.log({ inputElm });

      inputElm.type = 'email';
    }
  }, [stytchElmRef.current]);

  return (
    <div className="AccountLogin__form" ref={stytchElmRef}>
      <StytchLogin {...stytchConfig} />
    </div>
  );
};

export default StytchLoginForm;

interface AuthLoginFormProps {
  onLogin?: () => void;
  path?: string;
  variant?: CtaProps['variant'];
}
