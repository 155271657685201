import React, { useCallback, useEffect, useState } from "react";

import { useUser } from '@chordcommerce/gatsby-theme-autonomy';
import { StyledAmbassadorPerformance } from './AmbassadorPerformance.style';
import { Wrapper } from '~/styles/Wrapper.styled';
import { AmbassadorStats } from './AmbassadorStats';
import { AmbassadorCommissions } from './AmbassadorCommissions';
import { NumberTicker } from './NumberTicker';
import { exigo } from '~/utils/exigo';
import CountUp from 'react-countup';
import Loader from '~/components/Loader/Loader';
import { Cta } from '~/components/Generic/Cta/Cta';
import { AmbassadorOrderDetailsModal } from '~/components/Account/AmbassadorPortal/AmbassadorOrderDetails';
import { Downline } from '~/components/Account/AmbassadorPortal/Downline/Downline';
import { addMonths, differenceInCalendarMonths, format } from 'date-fns';
import Arrow from '~/assets/images/icons/arrow.svg';
import { RANKS } from '~/types/constants';
import { exportToCsv } from "~/utils/tools";

const BASE_PERIOD = new Date('2022-09-01T05:00:00.000Z');
const MAX_PERIOD = differenceInCalendarMonths(Date.now(), BASE_PERIOD);

export const AmbassadorPerformance = () => {
  const { user } = useUser();
  const [isLoadingDownline, setIsLoadingDownline] =
    useState<boolean>(undefined);
  const [downline, setDownline] = useState<any>(undefined);
  const [selectedAmbassador, setSelectedAmbassador] = useState<any>(null);
  const [currentPeriod, setCurrentPeriod] = useState<number>(
    differenceInCalendarMonths(new Date(`${(new Date).toISOString().split('T').shift()}T05:00:00.000Z`), BASE_PERIOD)
  );

  useEffect(() => {
    if (
      (typeof isLoadingDownline === 'undefined' &&
        typeof window !== 'undefined') ||
      !isLoadingDownline
    ) {
      setIsLoadingDownline(true);
      const fetchStats = async () => {
        console.log(`Fetching downline...`);
        const exigoDownline = await exigo('downline?periodId=' + (currentPeriod + 1));
        setDownline(exigoDownline);
        console.log({ exigoDownline });
        setIsLoadingDownline(false);
      };
      fetchStats();
    }
  }, [user, currentPeriod]);

  const goToPreviousMonth = () => {
    if (currentPeriod > 0) setCurrentPeriod(currentPeriod - 1);
  };
  const goToNextMonth = () => {
    if (currentPeriod < MAX_PERIOD) setCurrentPeriod(currentPeriod + 1);
  };
  // console.log({downline, user, currentPeriod, MAX_PERIOD})
  const userNode = downline?.nodes?.find?.((n: any) => n.level === 0);
  const personalVolume = userNode?.volume2 || 0;
  const teamVolume = (userNode?.volume5 || 0) - personalVolume;

  const monthViewing = addMonths(new Date('2022-09-01T05:00:00.000Z'), currentPeriod);

  const downloadReport = useCallback(() => {
    const rows = [
      ['Downline Report', format(monthViewing, 'MMMM yyyy')],
      [],
      ['Level', 'ID', 'Name', 'Enrollment Date', 'Rank', 'MSR', 'PV', 'TV', 'DV', 'LPV']
    ];
    downline.nodes.forEach((node: any) => {
      try {
        const newRow = [];
        newRow.push(node.level || '');
        newRow.push(node.customerID || '');
        newRow.push([node.firstName, node.lastName].filter((v:any) => v).join(' '));
        newRow.push(node.joinDate ? format(new Date(node.joinDate), 'dd MMM yyyy') : '');
        newRow.push(RANKS[`${node.payRankID}` as keyof typeof RANKS].name || 'N/A');
        newRow.push(RANKS[`${node.payRankID}` as keyof typeof RANKS].msr || '');
        newRow.push(`$${node.volume2.toFixed(2)}`);
        newRow.push(`$${(node.volume5 - node.volume2).toFixed(2)}`);
        newRow.push(`$${node.volume5.toFixed(2)}`);
        newRow.push(`$${node.volume9.toFixed(2)}`);
        rows.push(newRow);
      } catch (e) {
        console.error(e);
      }
    });
    exportToCsv(`Downline Report - ${format(monthViewing, 'MMMM yyyy')}.csv`, rows);
  }, [downline, monthViewing])
  return (
    <Wrapper width="normal" gutter={false}>
      <StyledAmbassadorPerformance>
        <h1>Performance</h1>
        <div className="AmbassadorPerformance__counters">
          <NumberTicker
            separator=","
            prefix="$"
            decimals={0}
            loading={isLoadingDownline !== false}
            end={personalVolume}
            title="Personal Volume"
            subtitle="Your total sales volume"
          />
          <NumberTicker
            separator=","
            prefix="$"
            decimals={0}
            loading={isLoadingDownline !== false}
            end={teamVolume}
            title="Team Volume"
            subtitle="Your team's total sales volume"
          />
        </div>

        {/* <AmbassadorStats /> */}
        {/* <AmbassadorCommissions showAll /> */}
        <div className="AmbassadorPerformance__downline">
          <div>
            <h2>Your Downline</h2>
            <div className="AmbassadorPerformance__downline__month">
              <button
                type="button"
                onClick={goToPreviousMonth}
                disabled={currentPeriod === 1}
                className="prev"
              >
                <Arrow />
              </button>
              <span>{format(monthViewing, 'MMMM yyyy')}</span>
              <button
                type="button"
                onClick={goToNextMonth}
                disabled={currentPeriod === MAX_PERIOD}
                className="next"
              >
                <Arrow />
              </button>
            </div>
          </div>
          <Cta className="export-btn" onClick={() => downloadReport()}>Download full report</Cta>
        </div>
        {isLoadingDownline ? (
          <Loader text="Loading your downline" />
        ) : (
          <Downline
            downloadReport={() => downloadReport()}
            downline={downline?.nodes}
            onOpenDetails={ambassador => setSelectedAmbassador(ambassador)}
          />
        )}
        <AmbassadorOrderDetailsModal
          isOpen={!!selectedAmbassador}
          onClose={() => setSelectedAmbassador(null)}
          ambassador={selectedAmbassador}
        />
      </StyledAmbassadorPerformance>
    </Wrapper>
  );
};
