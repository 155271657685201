import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import { Cta } from '~/components/Generic/Cta/Cta';
import { Any } from '../../../../../types/types';

const ShippingSummary: React.FC<Any> = ({ subscription, openForm }) => {
  const translate = useTranslate();
  return (
    <div>
      <p>{subscription.shipAddress.address1}</p>
      <p>{subscription.shipAddress.address2}</p>
      <p>{subscription.shipAddress.city}</p>
      <p>{subscription.shipAddress.state.name} </p>

      <p>
        <strong>{translate('subscriptions.next_shipment')} </strong>
        {subscription.state !== 'canceled'
          ? new Date(subscription.actionableDate).toLocaleDateString()
          : translate('subscriptions.cancelled')}
      </p>

      <Cta variant="secondary" onClick={openForm} type="submit">
        {translate('subscriptions.edit')}
      </Cta>
    </div>
  );
};

export default ShippingSummary;
