import React from "react";
import { AmbassadorCommissions } from "./AmbassadorCommissions";
import { AmbassadorHeader } from "./AmbassadorHeader";
import { StyledAmbassadorPortal } from "./AmbassadorPortal.style";
import { AmbassadorResources } from "./AmbassadorResources";
import { AmbassadorStats } from "./AmbassadorStats";

export const AmbassadorPortal = () => {
  return (
    <StyledAmbassadorPortal>
      <AmbassadorHeader />
      {/* <AmbassadorStats /> */}
      {/* <AmbassadorCommissions /> */}
      <AmbassadorResources />
    </StyledAmbassadorPortal>
  )
}