import { navigate } from 'gatsby';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslate, useUser } from '@chordcommerce/gatsby-theme-autonomy';
import { Cta } from '~/components/Generic/Cta/Cta';

const AccountProfileForm = () => {
  const translate = useTranslate();
  const { user, modifyUser } = useUser();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: user.data.email,
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    setError(null);

    try {
      await modifyUser(data);
      navigate('/account');
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="AccountProfile__form">
      <label htmlFor="email">{translate('profile.email_input_label')}</label>
      <input className="AccountProfile__input" ref={register()} name="email" type="text" />
      {error && <p>{error.message}</p>}
      <Cta type="submit">
        {loading && <p>Loading...</p>}
        {!loading && translate('profile.submit_button')}
      </Cta>
    </form>
  );
};

export default AccountProfileForm;
