import { selectors } from '@chordcommerce/gatsby-theme-autonomy'
import { Link } from 'gatsby';
import { trackClickProduct } from '@chordcommerce/gatsby-theme-autonomy/src/redux/actions/analytics';
import { useDispatch } from 'react-redux';
import useProductMainImage from '~/hooks/components/use-product-main-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FC } from 'react';
import { useSelector } from 'react-redux'

const SummaryImage: FC<SummaryImageProps> = ({ slug, sku, lineItem }) => {
  const path = sku.includes('|') ? 'bundles' : 'products';
  const url = `/${path}/${slug}/`;
  const dispatch = useDispatch();
  const { image } = useProductMainImage({ slug, sku });
  const { getAllProducts } = selectors
  const products: ChordProduct[] = useSelector(getAllProducts)
  const product: any = products.find(p => p.slug === slug) || {};
  if (!image) {
    return null;
  }
  return (
    <div className="ItemSummary">
      <Link to={url} onClick={() => dispatch(trackClickProduct(slug))}>
        <GatsbyImage image={getImage(image)} alt={image.title} />
        <p>{lineItem.quantity} x {product?.name}</p>
      </Link>
    </div>
  );
};

export default SummaryImage;

export interface SummaryImageProps {
  slug: string;
  sku: string;
  lineItem: LineItem
}