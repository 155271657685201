import styled from "styled-components";
export const StyledAmbassadorCreateOrder = styled.div`
h1 {
  font-family: var(--primaryFont);
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 58px;
  letter-spacing: -0.01em;
  em {
    font-size: inherit;
  }
}
`