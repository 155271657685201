import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { ProductListPage } from "~/components/ProductListPage/ProductListPage";
import { StyledAmbassadorCreateOrder } from "./AmbassadorCreateOrder.style";

export const AmbassadorCreateOrder = () => {
  return (
    <StaticQuery query={graphql`
      query AmbassadorCreateOrderQuery {
        catalog: contentfulCatalog(
          slug: { eq: "catalog" }
        ) {
          collections {
            ...CollectionDetailsFragment
          }
        }
      }`} render={data => {
        return (
          <StyledAmbassadorCreateOrder>
            <h1>Start a product wishlist<br /><em>for someone you know</em></h1>
            <ProductListPage
              sections={[]}
              collections={data.catalog.collections}
              hideAmbassadorCard
              noPrefetch
              isSharedOrder
            />
          </StyledAmbassadorCreateOrder>
        )
      }} />
  );
}