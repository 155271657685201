import styled from 'styled-components';

export const StyledAccount = styled.div`
  min-height: calc(100vh - 42px - 55px - 477px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
.PrivateRoute__back {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  > *  {
    margin: 0 auto;
    width: auto;
  }
}

.AccountOrders {
  &__orders {
    margin: 25px 0;
    width: 100%;
    a {
      color: var(--black);
    }
    th {
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: #1F1F1F;
      text-align: left;
      &:first-child, &:last-child {
        text-align: center;
      }
    }
    tr {
      border-bottom: 1px solid rgba(19, 19, 19, 0.2);
    }
    td, th {
      padding: 25px 0;
      border-bottom: 1px solid rgba(19, 19, 19, 0.2);
    }
    td {
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: var(--black);
      text-align: left;
      &:first-child, &:last-child {
        text-align: center;
      }
    }
  }
}

.AccountLogin__ambassador {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4rem auto 1rem auto;
  padding: 3rem 2rem;
  border-radius: 10px;
  background: var(--gray);
  @media (max-width: 1024px) {
    padding: 2rem 1rem;
  }
  position: relative;
  &Content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    width: 100%;
    @media (max-width: 1024px) {
      grid-template-columns: 1fr; 
    }
  }
  &Value {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 282px;

    h3 {
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: var(--black);
      margin: 0 auto 8px auto;
    }
    p {
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: var(--black);
    }
  }

  &Title {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 55px;
    text-align: center;
    color: var(--black);
    max-width: 580px;
    margin-bottom: 3rem;

    @media (max-width: 1024px) {
      font-size: 38px;
      line-height: 42px;
    }

    p {
      margin: 0;
    }

  }
}

.SubscriptionDetails {
  .cta--secondary {
    margin-right: 1rem;
    @media (max-width: 500px) {
      width: auto;
    }
  }
  [id="rswp-card-button"] {
    background: var(--orange);
    max-width: 175px;
  }
  &__Actions {
    max-width: 650px;
    border-radius: 10px;
    padding: 1rem 1rem 2rem 1rem;
    background: var(--lime);
    margin: 0 auto;
  }
  &__Addresses {
    input {
      width: 100%;
    }
    margin: 2rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 768px) { 
      grid-template-columns: 1fr;
    }
    grid-gap: 1rem;
    > div {
      border-radius: 10px;
      padding: 1rem;
      background: var(--gray);
    }
    p {
      margin: 0;
      strong {
        font-weight: 500;
        display: inline-block;
        margin-top:1rem;
        margin-right: 1rem;
      }
    }
  }
}

.AccountLogin, .AccountPage, .AccountSubscriptions, .AccountProfile, .AccountOrders, .SubscriptionDetails {
  margin-bottom:2rem;

  .ItemSummary {
    margin: 1rem 0;
    >a {
      display: grid;
      grid-gap: 1rem;
      align-items: center;
      color: var(--black);
      grid-template-columns: 50px 1fr;
    }    
  }

  .AccountSubscriptionList {
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
  .AccountSubscriptionCard {
    display: flex;
    flex-direction: column;
    background: var(--gray);
    border-radius: 10px; 
    padding: 1rem;
    p {
      margin: 0;
    }
    h2 {
      margin: 0 auto;
    }
    h3 {
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      margin: 0.5rem auto;
    }
  }
  .AccountPage__actions {
    display:grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    max-width: 225px;
    margin: 1rem auto;
  }

  h1 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 55px;
    text-align: center;
    color: var(--black);
    em {
      font-size: inherit;
      line-height: inherit;
      line-height: 1.5;
    }
  }

  h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 300;
    font-size: 1.25rem;
    text-align: center;

  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
    input {
      opacity: 0.3;
      transition: opacity 0.3s ease-in-out;
      &:focus{
        opacity: 1;
      }
      margin: 2rem auto;
      width: 100%;

      border: 1px solid var(--black);
      padding:1rem 2rem;
      border-radius: 99px;
      &.AccountProfile__input {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 768px) {
  min-height: calc(100vh - 18px - 56px - 670px - 16px);
}
`