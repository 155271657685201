import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledArticleCard = styled.article`
  margin-inline: var(--gutter);

  @media (min-width: 768px) {
    margin-inline: 0;
  }

  .article-card--thumbnail {
    width: 100%;
    border-radius: var(--radius);
    position: relative;
    &:after {
      content: '';
      display: block;
      padding-top: 72%;
    }
    > div, img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
    }
  }

  .article-card--cardTitle {
    font-size: ${pxtorem(16)};
    font-weight: 500;
    line-height: 1.2;
    margin: ${pxtorem(20)} 0 ${pxtorem(10)};
    text-transform: uppercase;
  }

  .article-card--cardDesc {
  }

  .article-card--cardDate {
    font-size: ${pxtorem(12)};
    margin-top: ${pxtorem(15)};
  }

  a {
    text-decoration: none;
    color: var(--black);
    transition: color 100ms linear;

    .article-card--thumbnail img {
      transition: transform 0.3s var(--cubic-ease);
    }

    &:hover {
      color: var(--green);

      .article-card--thumbnail img {
        transform: scale(1.06);
      }
    }
  }
`;
