import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';

import { StyledAmbassadorResourceCard } from '~/components/Account/AmbassadorPortal/AmbassadorResourceCard.style';

export const StyledAmbassadorNews = styled.div`
  .article-list--text-banner {
    text-align: center;
    margin-inline: auto;

    h1 {
      font-size: clamp(${pxtorem(40)}, 5vw, ${pxtorem(60)});
      font-family: var(--secondaryFont);
      font-style: italic;
      line-height: 100%;
      margin: ${pxtorem(22)} 0 0;
      margin: 0;
    }

    @media (min-width: 768px) {
      max-width: 60%;
    }

    @media (min-width: 1280px) {
      max-width: 50%;
    }
  }

  .items-list--grid {
    display: grid;

    place-content: center;
    place-items: top;
    grid-template-columns: minmax(360px, 1fr);
    gap: ${pxtorem(40)};

    justify-items: center;
    margin: ${pxtorem(40)} auto ${pxtorem(60)};

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(360px, 1fr));
      gap: ${pxtorem(80)} ${pxtorem(20)};
      &__events {
        gap: 0 ${pxtorem(40)};
      }
    }

    @media (min-width: 1280px) {
      grid-template-columns: repeat(3, minmax(380px, 1fr));
    }
  }

  ${StyledAmbassadorResourceCard} {
    max-width: none;
  }
`;
