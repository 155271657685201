import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import { Section } from '~/styles/Section.styled';
import { Wrapper } from '~/styles/Wrapper.styled';
import ShowOnScroll from '~/components/Generic/ShowOnScroll/ShowOnScroll';
import { ArticleCard } from '~/components/ArticleCard/ArticleCard';
import {
  AmbassadorResourceCard,
  AmbassadorResourceCardItem,
} from './AmbassadorResourceCard';

import { StyledAmbassadorNews } from './AmbassadorNews.style';

export const AmbassadorNews = () => {
  return (
    <StaticQuery
      query={graphql`
        query AmbassadorNewsQuery {
          articles: allContentfulAmbassadorNews(
            sort: { fields: [publishDate], order: DESC }
          ) {
            edges {
              node {
                ...AmbassadorNewsCardFragment
              }
            }
          }
          events: allContentfulAmbassadorEvent(
            sort: { fields: [date], order: DESC }
          ) {
            edges {
              node {
                ...AmbassadorEventCardFragment
              }
            }
          }
        }
      `}
      render={data => {
        const articles = data.articles.edges.map(({ node }) => node);
        const events = data.events.edges.map(({ node }) => node);
        return (
          <StyledAmbassadorNews>
            <Section>
              <Wrapper width="wide">
                <ShowOnScroll>
                  <div className="article-list--text-banner">
                    <h1>Ambassador News</h1>
                  </div>
                </ShowOnScroll>
              </Wrapper>
            </Section>

            <Section>
              <Wrapper width="normal">
                <div className="items-list--grid">
                  {articles.length &&
                    articles.map((a, i) => (
                      <ShowOnScroll key={`article-${a.slug}`} delay={50 * i}>
                        <ArticleCard root="ambassador-news/" article={a} />
                      </ShowOnScroll>
                    ))}
                </div>
              </Wrapper>
            </Section>

            <Section className="events-list">
              <Wrapper width="normal">
                <h2>What's happening now</h2>
                <div className="items-list--grid items-list--grid__events">
                  {events.length &&
                    events.map((e, i) => (
                      <ShowOnScroll key={`event-${e.slug}`} delay={50 * i}>
                        <AmbassadorResourceCard
                          item={e as AmbassadorResourceCardItem}
                        />
                      </ShowOnScroll>
                    ))}
                </div>
              </Wrapper>
            </Section>
          </StyledAmbassadorNews>
        );
      }}
    />
  );
};
