import styled from 'styled-components';
export const StyledOrderConfirmation = styled.div`
  h1, .OrderConfirmation__itemsTitle {
    font-size: 2.5rem;
  }
  .OrderConfirmation__subtitle {
    em {
      /* text-decoration: underline; */
      font-weight: 400;
      font-style: normal;
      font-family: var(--primaryFont);
    }
  }
  .OrderConfirmation__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    h2 {
      margin: 0;
      font-size: 2rem;
      margin-bottom:1rem;
    }
    p {
      margin: 0;
    }
  }

  .OrderConfirmation__items {
    display: flex;
    flex-direction: column;
  }

  .OrderConfirmation__totals {
    display: flex;
    flex-direction: column;
  }

  .OrderConfirmation__totalItem {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    &:first-child {
      padding-top: 1rem;
      border-top: 1px solid var(--gray);
    }
    &__title {
      font-weight: 400;
    }
    &__price {
      font-weight: normal;
      &.is-pending {
        font-weight: 300;
      }
    }
    &__subtitle {
      font-weight: 300;
      display: block;
    }
    &__removeBtn {
      font-weight: 200;
      cursor: pointer;
      appearance: none;
      border: 0;
      background: transparent;
      padding: 2px;
      text-decoration: underline;
      color: var(--darkGray);
      font-size: 13px;
        
    }
  }
    
  .OrderConfirmation__total {
    border-bottom: 1px solid var(--gray);
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    &__title {
      font-weight: 400;
      font-size: 1.25rem;
    }

    &__price {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }

  .OrderConfirmation__item {
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 75px 1fr;
    grid-column-gap: 0.5rem;
    &__image {
      position: relative;
      &:after {
        padding-top: 100%;
        content: "";
        display: block;
      }
      img {
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        object-position: center center;
        border-radius: 4px;
        display: block;
        width: 100%;
        height: 100%;
      }
      &__quantity {
        position: absolute;
        top:-12px;
        right: -12px;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        padding: 0
        border-radius: 100%;
        line-height: 0;
        display: block;
        border-radius: 100%;
        z-index: 1;
        background-color: var(--green);
        position: absolute;
        text-align: center;
 
        &:before, &:after {
          padding-top: 50%;
          content: "";
          display: block;
          width:25px;
        }
      }
    }
    &__info {
      margin-left:5px;
      align-self: center;
      display: flex;
      position: relative;
      &__subtitle {
        display: block;
        color: var(--darkGray);
      }
      &__price {
        margin-left: auto;
        width: 33%;
        text-align: right;
        &__original {
          text-decoration: line-through;
          color: var(--darkGray);
          display: block;
          opacity: 0.8;
        }
      }
      font-weight: 400;
    }
  }

`
  
