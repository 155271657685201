import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import AccountProfileForm from '../Form'

const AccountProfilePage = () => {
  const translate = useTranslate()

  return (
    <div className="AccountProfile">
      <div>
        <h1>
          {translate('profile.page_title')}
        </h1>
        <AccountProfileForm />
      </div>
    </div>
  )
}

export default AccountProfilePage
