import { useUser } from '@chordcommerce/gatsby-theme-autonomy';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import React, { Fragment, useEffect, useState } from 'react';
import { Section } from '../../styles/Section.styled';
import { Wrapper } from '../../styles/Wrapper.styled';
import { ShopPageData } from '../../templates/shop';
import { AMBASSADOR_ENROLLMENT_MAXDATE, intentSlugs } from '../../types/constants';
import { Collection, IntentSlug, IProduct } from '../../types/types';
import { AmbassadorPLPCard } from '../AmbassadorPLPCard/AmbassadorPLPCard';
import { FeaturedKits } from '../FeaturedKits/FeaturedKits';
import { Cta } from '../Generic/Cta/Cta';
import { Dropdown, DropdownOption } from '../Generic/Dropdown/Dropdown';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { Modal } from '../Modal/Modal';
import { ProductCard } from '../ProductCard/ProductCard';
import { TooltipMenu } from '../TooltipMenu/TooltipMenu';
import { PLPGrid, StyledPLP, StyledPLPModal } from './PLP.style';

export const ProductListPage: React.FC<ProductListPageProps> = ({
  collections,
  sections,
  hideAmbassadorCard,
  noPrefetch,
  preSelectedIntent,
  preSelectedType,
  isSharedOrder = false
}) => {
  //#region Hooks
  const threeColumns = useMediaQuery('(min-width: 1280px)');
  const twoColumns = useMediaQuery('(min-width: 768px) and (max-width: 1279px)');
  const [isReset, resetHandler] = useDisclosure(false);
  const [modalOpen, modalHandlers] = useDisclosure(false);
  const [sortType, setSortType] = useState<SortOption | null>(null);
  const { user } = useUser();
  const [productTypes] = useState<DropdownOption[]>(
    collections
      .filter(({ productType, slug }) => productType && slug !== 'all')
      .map(({ name, slug }) => ({ value: slug, label: name }))
  );
  const [intentTypes] = useState<DropdownOption[]>(
    collections
      .filter(({ productType }) => !productType)
      .map(({ name, slug }) => ({ value: slug, label: name }))
  );
  const [selectedIntent, setSelectedIntent] = useState<DropdownOption>(
    preSelectedIntent
      ? intentTypes.find(({ value }) => value === preSelectedIntent)
      : null
  );
  const [selectedProductType, setSelectedProductType] =
    useState<DropdownOption>(
      preSelectedType
        ? productTypes.find(({ value }) => value === preSelectedType)
        : null
    );

  const [allProducts] = useState(() => {
    const flatMap = collections.flatMap(c => c.products.map(p => p));

    return flatMap.filter((p, i) => {
      return !p.hidden && flatMap.findIndex(c => c.slug === p.slug) === i;
    });
  });
  const [productContent, setProductContent] = useState<JSX.Element[]>([]);
  const [filteredProducts, setFilteredProducts] = useState(allProducts);

  useEffect(() => {
    const ambassadorIndex = threeColumns ? 3 : twoColumns ? 2 : 1;
    let ambassadorCardCount = 0;
    const availableProducts = (filteredProducts || []).filter(({ ambassadorOnly }) => !ambassadorOnly || (!isSharedOrder && ambassadorOnly && user?.data?.roles?.includes?.('ambassador')))
    const now = new Date();
    const mapped = availableProducts.map((p, i) => {
      const showAmbassadorCard =
        now.getTime() > AMBASSADOR_ENROLLMENT_MAXDATE.getTime() ? false :
        !hideAmbassadorCard && !(user?.data?.roles?.includes?.('ambassador')) &&
        ambassadorCardCount === 0 &&
        (i === ambassadorIndex ||
          selectedIntent ||
          selectedProductType ||
          i === availableProducts.length - 1);
  
      const intentCollections = p.collection?.filter(
        col =>
          intentSlugs.includes(col.slug as IntentSlug) && col.slug !== 'all'
      );
      
      if (showAmbassadorCard) {
        ambassadorCardCount++;
        return (
          <Fragment key={`${p.slug}`}>
            <ShowOnScroll className="ambassador-signup">
              <AmbassadorPLPCard />
            </ShowOnScroll>
            <ShowOnScroll>
              <ProductCard
                product={p}
                intentCollections={intentCollections}
                noPrefetch={!!noPrefetch}
                isSharedOrder={isSharedOrder}
              />
            </ShowOnScroll>
          </Fragment>
        );
      }
  
      return (
        <ShowOnScroll key={`p_${p.slug}_${i}`}>
          <ProductCard
            product={p}
            noPrefetch={!!noPrefetch}
            intentCollections={p.collection?.filter(col => !col.productType)}
            isSharedOrder={isSharedOrder}
          />
        </ShowOnScroll>
      );
    });
    setProductContent(mapped);
  }, [filteredProducts, twoColumns, threeColumns, user]);

  useEffect(() => {
    if (isReset) {
      handleProductsFilter();
    }
  }, [isReset]);

  useEffect(() => {
    handleProductsFilter();
  }, []);
  //#endregion
  //#region Handlers
  const handleProductsFilter = (_type?: any, _intent?: any, _sort?: SortOption) => {
    const type = typeof _type === 'undefined' ? selectedProductType : _type;
    const intent = typeof _intent === 'undefined' ? selectedIntent : _intent;
    const sort = typeof _sort === 'undefined' ? sortType : _sort;
    const newFiltered = allProducts.filter(p => {
      const isSameIntent = !intent
        ? true
        : p.collection?.some(({ slug }) => slug === intent.value);
      const isSameProductType = !type
        ? true
        : p.collection?.some(({ slug }) => slug === type.value);
      return isSameIntent && isSameProductType;
    });

    const sorted = sortProducts(newFiltered, sort);
    setFilteredProducts(sorted);
    const newPath = `/shop/${type?.value || 'all'}/${intent?.value || 'all'}`
      .split('/all/all')
      .join('');
    if (window.location.pathname.indexOf('/shop') === 0) {
      window.history.pushState({}, '', newPath);
    }
  };

  const sortProducts = (products: IProduct[], sort?: SortOption) => {
    if (!sort) { 
      return products;
    }

    if (sort === "high-low") {
      return products.sort((a, b) => b.variants[0].price - a.variants[0].price);
    }
    
    if (sort === "low-high") {
      return products.sort((a, b) => a.variants[0].price - b.variants[0].price);
    }
  }

  const resetFilters = () => {
    setSelectedIntent(null);
    setSelectedProductType(null);
    resetHandler.open();
  };

  //#endregion
  //#region Derived State
  const [headerSection, modalSection] = sections.map(s => ({
    ...s,
    cta: JSON.parse(s.cta.raw),
  }));

  const headerCtaText = headerSection?.cta?.content?.[0]?.content?.[0]?.value;
  const modalCtaText = modalSection?.cta?.content?.[0]?.content?.[0]?.value;
  const hasProducts = !!filteredProducts.length;
  //#endregion
  return (
    <StyledPLP>
      <Section>
        <div className="wrapper">
          {headerSection && (
            <ShowOnScroll>
              <div
                className="plp--text-banner"
                dangerouslySetInnerHTML={{
                  __html: headerSection.description.childMarkdownRemark.html,
                }}
              />
            </ShowOnScroll>
          )}
          {headerCtaText && (
            <ShowOnScroll delay={100} animateCta>
              <Cta
                href="#"
                variant="secondary"
                onClick={modalHandlers.open}
                className="modal-cta"
              >
                {headerCtaText}
              </Cta>
            </ShowOnScroll>
          )}
          <ShowOnScroll delay={150} className="plp--filter--show-scroll">
            <div className="plp--filters">
              <div className="dropdowns">
                <Dropdown
                  label="Benefits"
                  options={intentTypes}
                  value={selectedIntent}
                  onReset={() => [
                    setSelectedIntent(null),
                    handleProductsFilter(undefined, null),
                  ]}
                  onChange={val => [
                    setSelectedIntent(val),
                    handleProductsFilter(undefined, val),
                  ]}
                />
                <Dropdown
                  label="Formats"
                  options={productTypes}
                  value={selectedProductType}
                  onReset={() => [
                    setSelectedProductType(null),
                    handleProductsFilter(null, undefined),
                  ]}
                  onChange={val => [
                    setSelectedProductType(val),
                    handleProductsFilter(val, undefined),
                  ]}
                />
              </div>
              <div className="sort-wrapper">
                <TooltipMenu
                  label={'Sort'}
                  value={sortType}
                  onChange={value => [
                    setSortType(value as SortOption),
                    handleProductsFilter(
                      selectedProductType,
                      selectedIntent,
                      value as SortOption
                    ),
                  ]}
                  options={sortOptions}
                />
              </div>
            </div>
          </ShowOnScroll>
        </div>
        {!hasProducts && (
          <ShowOnScroll>
            <p className="empty-filter">No products match these filters.</p>
            <Cta
              href="#"
              variant="secondary"
              onClick={resetFilters}
              className="filter--reset"
            >
              Reset
            </Cta>
          </ShowOnScroll>
        )}
        <Wrapper width="wide" gutter>
          <PLPGrid>{hasProducts && productContent.map((p => p))}</PLPGrid>
        </Wrapper>

        <Section bg="green">
          <Wrapper gutter>
            <FeaturedKits noPrefetch={noPrefetch} />
          </Wrapper>
        </Section>

        {modalSection && (
          <Modal isOpen={modalOpen} onClose={modalHandlers.close}>
            <StyledPLPModal>
              <p className="modal--title">{modalSection.title}</p>
              <div
                className="modal--content"
                dangerouslySetInnerHTML={{
                  __html: modalSection.description.childMarkdownRemark.html,
                }}
              />

              <Cta variant="secondary" href={`/learn`} className="modal--link">
                {modalCtaText}
              </Cta>
            </StyledPLPModal>
          </Modal>
        )}
      </Section>
    </StyledPLP>
  );
};

interface ProductListPageProps {
  collections: Collection[];
  sections: ShopPageData['page']['sections'];
  hideAmbassadorCard?: boolean;
  noPrefetch?: boolean;
  preSelectedIntent?: string;
  preSelectedType?: string;
  isSharedOrder?: boolean;
}

type SortOption = 'high-low' | 'low-high'; // | 'rating' ;

type SortMenuOptions = {
  label: string;
  value: SortOption;
}[];

const sortOptions: SortMenuOptions = [
  {
    label: 'Price (Low - High)',
    value: 'low-high',
  },
  {
    label: 'Price (High - Low)',
    value: 'high-low',
  },
  
];

