import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery, usePagination, useSetState } from '@mantine/hooks';
import { ComponentProps } from 'react';
import { Cta } from '~/components/Generic/Cta/Cta';
import { Modal } from '~/components/Modal/Modal';
import {
  StyledAmbassadorOrderDetails,
  StyledMobileOrders,
} from './AmbassadorOrderDetails.styled';
import { exigo } from '~/utils/exigo';
import Loader from '~/components/Loader/Loader';
import { Pagination } from '~/components/Pagination/Pagination';
import { exportToCsv } from '~/utils/tools';

const styleProps: ComponentProps<typeof Modal>['styleProps'] = {
  bg: 'white',
  padding: '40px',
  maxWidth: 1280,
  fullScreenMobile: true,
  breakpoint: 1048,
  scrollable: true,
};

export const AmbassadorOrderDetailsModal = ({
  isOpen,
  onClose,
  ambassador,
}: OrderDetailsModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} styleProps={styleProps}>
      {ambassador && <AmbassadorOrderDetails ambassador={ambassador} />}
    </Modal>
  );
};


const AmbassadorOrderDetails: React.FC<OrderDetailsProps> = ({
  ambassador,
}) => {
  const isMobileView = useMediaQuery('(max-width: 1048px)');
  const PER_PAGE = 15;
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>('');
  const [state, setState] = useSetState({
    orders: [],
    loading: false,
    totalPages: 0,
  });
  const [lineItems, setLineItems] = useState({ isOpen: false, rowIdx: null });
  const { setPage, active: activePage } = usePagination({
    initialPage: 1,
    total: state.totalPages,
  });

  useEffect(() => {
    if (ambassador && state.orders.length <= activePage * PER_PAGE) {
      console.log(`Fetching page ${activePage}`);
      fetchOrders();
    }
  }, [ambassador, activePage, state.orders.length]);

  const runExport = useCallback(async () => {
    console.log('runExport', ambassador, loadingExport);
    if (loadingExport) return;
    setLoadingExport(true);
    const allOrders = [];
    let hasMoreOrders = false;
    let page = 1;
    let totalOrders = 0;
    do {
      const percentage = Math.round(
        (allOrders.length / (totalOrders || 1)) * 100
      );
      setLoadingText(`Exporting orders ${percentage}%`);
      const url = `downline-orders?page=${page}&ambassadorId=${ambassador.customerID}`;
      const response = await exigo(url);
      const { orders, current_page, pages, count } = response;
      totalOrders = count;
      allOrders.push(...orders);
      hasMoreOrders = page < pages;
      ++page;
    } while (hasMoreOrders && page < 25);
    console.log({ allOrders });
    setLoadingText(`Generating report...`);
    const lines = [
      [
        'Order detail by Ambassador',
        'Generated on ' + new Date().toLocaleString(),
      ],
      [],
      ['Ambassador', `${ambassador.firstName} ${ambassador.lastName}`],
    ];
    if (ambassador.email) {
      lines.push(['Email', ambassador.email]);
    }
    if (ambassador.phone) {
      lines.push(['Phone', ambassador.phone]);
    }
    if (ambassador.customerID) {
      lines.push(['ID', ambassador.customerID]);
    }
    lines.push([]);
    lines.push([
      'Order Date',
      'Order Number',
      'Type',
      'QV',
      'Cart',
      'Subscription',
    ]);
    allOrders.forEach(order => {
      lines.push([
        new Date(order.completed_at).toLocaleDateString(),
        order.number,
        order.order_type,
        (1 * order.qualifyingVolume).toFixed(2),
        order.simplified_line_items.map((v: any) => `- ${v.quantity}x ${v?.text || 'Unknown item'}`).join('\n'),
        order.subscription_status,
      ]);
    });
    exportToCsv(`orders-${ambassador.customerID}.csv`, lines);
    setLoadingExport(false);
    setLoadingText(``);
  }, [loadingExport, ambassador]);

  const fetchOrders = useCallback(async () => {
    setState({ loading: true });
    const url = `downline-orders?page=${activePage}&ambassadorId=${ambassador.customerID}`;
    const json = await exigo(url)
    setState({
      orders: [...json.orders],
      loading: false,
      totalPages: Math.ceil(json.count / PER_PAGE),
    });
  }, [ambassador, activePage, isMobileView]);

  const handlePageUpdate = (page: number) => {
    setPage(page);
    setLineItems({ isOpen: false, rowIdx: null });
  };

  const openLineItems = (rowIdx: number) => {
    setLineItems({ isOpen: true, rowIdx });
  };

  const closeLineItems = () => {
    setLineItems({ isOpen: false, rowIdx: null });
  };

  const startIdx = (activePage - 1) * PER_PAGE;
  const endIdx = activePage * PER_PAGE;

  const ordersProps: AmbassadorOrdersProps = {
    orders: state.orders,
    openLineItems,
    closeLineItems,
    ambassador,
    endIdx,
    lineItems,
    startIdx,
  };
  return (
    <StyledAmbassadorOrderDetails className="orderDetails">
      <h3>Order detail by ambassador</h3>

      <div className={`grid${state.loading ? ' is-loading' : ''}`}>
        <div className="grid__left">
          <div className="grid__left__content">
            {ambassador.customerID && (
              <div>
                <p className="grid--label">Ambassador</p>
                <span>
                  {ambassador.firstName} {ambassador.lastName}
                </span>
              </div>
            )}
            {ambassador.email && (
              <div>
                <p className="grid--label">Email</p>
                <a href={`mailto:${ambassador.email}`}>{ambassador.email}</a>
              </div>
            )}
            {ambassador.phone && (
              <div>
                <p className="grid--label">Phone</p>
                <span>{ambassador.phone}</span>
              </div>
            )}
            <div>
              <p className="grid--label">ID</p>
              <span>{ambassador.customerID}</span>
            </div>
          
          </div>
          <Cta disabled={loadingExport} onClick={() => runExport()}>
            {loadingExport ? loadingText : <>Export full report</>}
          </Cta>
        </div>

        <div className={`grid__right`}>
          <div className="grid__loader">
            <Loader text="Loading orders..." />
          </div>
          {isMobileView ? (
            <MobileAmbassadorOrders {...ordersProps} />
          ) : (
            <DesktopAmbassadorOrders {...ordersProps} />
          )}
        </div>
      </div>
      <div className="pagination">
        <Pagination
          page={activePage}
          total={state.totalPages}
          onChange={handlePageUpdate}
          withControls={false}
          withEdges={false}
        />
      </div>
    </StyledAmbassadorOrderDetails>
  );
};

const AmbassadorOrderTableRow: React.FC<
  AmbassadorOrderTableRowProps
> = props => {
  const { order, ambassaddor, isOpen, onClose, onOpen, rowIdx } = props;
  const shipments = (order.shipments || []).filter(
    (ship: any) => ship.tracking
  );
  return (
    <>
      <tr className={isOpen ? 'open' : ''}>
        <td>{new Date(order.completed_at).toLocaleDateString()}</td>
        <td>{`${order.number}`.split('-').pop()}</td>
        <td className="tracking">
          {shipments.map((s: any) => {
            if (s.tracking_url) {
              return (
                <a
                  href={s.tracking_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Track{s.state === 'canceled' ? ' (Canceled)' : ''}
                </a>
              );
            }
            return <div>{s.tracking}{s.state === 'canceled' ? ' (Canceled)' : ''}</div>;
          })}
          {!shipments.length && order.state === 'canceled' && <div>Order Canceled</div>}
        </td>
        <td>{order.order_type}</td>
        <td>${(1 * order.qualifyingVolume).toFixed(0)}</td>
        <td>{order.subscription_status}</td>
        <td
          className={['cart', isOpen ? 'cart--open' : ''].join(' ')}
          onClick={isOpen ? onClose : () => onOpen(rowIdx)}
        >
          {isOpen ? 'Close Cart' : 'View more'}
        </td>
      </tr>
      {isOpen && (
        <tr className="cart-items">
          <td colSpan={7}>
            <div className="cart__content">
              {order.simplified_line_items.map((item: any) => (
                <div>
                  <span>{item.quantity}x</span>
                  <span>{item?.text || 'Unknown item'}</span>
                </div>
              ))}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const DesktopAmbassadorOrders: React.FC<AmbassadorOrdersProps> = props => {
  const {
    orders,
    openLineItems,
    ambassador,
    closeLineItems,
    lineItems,
    startIdx,
    endIdx,
  } = props;
  return (
    <table>
      <thead>
        <tr>
          <th>Order Date</th>
          <th>Order ID</th>
          <th>Tracking</th>
          <th>Type</th>
          <th>QV</th>
          <th>Subscription</th>
          <th>Cart</th>
        </tr>
      </thead>
      <tbody>
        {!orders.length
          ? null
          : orders
              .map((order: any, i: number) => (
                <AmbassadorOrderTableRow
                  order={order}
                  key={order.id}
                  rowIdx={i}
                  isOpen={lineItems.isOpen && lineItems.rowIdx === i}
                  onOpen={openLineItems}
                  onClose={closeLineItems}
                  ambassaddor={ambassador}
                />
              ))}
      </tbody>
    </table>
  );
};

const MobileAmbassadorOrders: React.FC<AmbassadorOrdersProps> = props => {
  const { orders, lineItems, openLineItems, closeLineItems, startIdx, endIdx } =
    props;
  const { isOpen, rowIdx } = lineItems;

  return (
    <StyledMobileOrders className="orders">
      {!orders.length
        ? null
        : orders.map((order: any, i: number) => {
            const shipments = (order.shipments || []).filter(
              (ship: any) => ship.tracking
            );
            const cartOpen = isOpen && rowIdx === i;
            return (
              <>
                <div className={["order__grid", cartOpen ? 'order__grid--open' : ''].join(" ")}>
                  <div className="order__grid__item">
                    <span className="order__grid__item__title">Order Date</span>
                    <span>
                      {new Date(order.completed_at).toLocaleDateString()}
                    </span>
                  </div>
                  <div className="order__grid__item">
                    <span className="order__grid__item__title">Order ID</span>
                    {`${order.number}`.split('-').pop()}
                  </div>
                  <div className="order__grid__item">
                    <span className="order__grid__item__title">Tracking</span>
                    {shipments.map((s: any) => {
                      if (s.tracking_url) {
                        return (
                          <a
                            href={s.tracking_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Track
                          </a>
                        );
                      }
                      return <div>{s.tracking}</div>;
                    })}
                  </div>
                  <div className="order__grid__item">
                    <span className="order__grid__item__title">Type</span>
                    <span>
                      {order.user_id === props.ambassador.id
                        ? 'Self'
                        : 'Customer'}
                    </span>
                  </div>
                  <div className="order__grid__item">
                    <span className="order__grid__item__title">QV</span>
                    <span>${(1 * order.qualifyingVolume).toFixed(0)}</span>
                  </div>
                  <div className="order__grid__item">
                    <span className="order__grid__item__title">Cart</span>
                    <button
                      className={[
                        'cart-btn',
                        isOpen && i === rowIdx ? 'cart-btn--open' : '',
                      ].join(' ')}
                      onClick={cartOpen ? closeLineItems : () => openLineItems(i)}
                    >
                      {cartOpen ? 'Close Cart' : 'View more'}
                    </button>
                  </div>
                {cartOpen && (
                  <div className="cart-items">
                    {order.line_items.map((item: any) => (
                      <div className="cart__content">
                        <span>{item.quantity}x</span>
                        <span>{item.variant.name}</span>
                      </div>
                    ))}
                  </div>
                )}
                </div>
              </>
            );
          })}
    </StyledMobileOrders>
  );
};

interface AmbassadorOrdersProps {
  orders: any[];
  openLineItems: (i: number) => void;
  closeLineItems: () => void;
  ambassador: any;
  startIdx: number;
  endIdx: number;
  lineItems: {
    isOpen: boolean;
    rowIdx: number;
  };
}
interface OrderDetailsProps {
  ambassador: any;
}

interface AmbassadorOrderTableRowProps {
  order: any;
  ambassaddor: any;
  isOpen: boolean;
  rowIdx: number;
  onOpen: (i: number) => void;
  onClose: () => void;
}

interface OrderDetailsModalProps extends OrderDetailsProps {
  isOpen: boolean;
  onClose: () => void;
}
