import { flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import React from 'react';
import { Cta } from '../Generic/Cta/Cta';
import { StyledTooltipMenu } from './TooltipMenu.style';

export const TooltipMenu: React.FC<TooltipMenuProps> = ({
  onChange,
  options,
  value,
  label,
}) => {
  const [isOpen, openHandlers] = useDisclosure(false);
  const ref = useClickOutside(openHandlers.close);

  const { x, y, strategy, reference, floating } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(-60), flip(), shift()],
  });
  const valueLabel = options.find(opt => opt.value === value)?.label;
  return (
    <StyledTooltipMenu ref={ref}>
      <Cta
        href="#"
        variant="secondary"
        ref={reference}
        onClick={openHandlers.open}
      >
        {valueLabel ?? label}
      </Cta>
      {isOpen && (
        <div
          ref={floating}
          className="tooltip"
          style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
          role="subscription-tooltip"
        >
          {options.map(({ label, value }, i) => (
            <button
              key={`${label}_${i}`}
              onClick={() => [onChange(value), openHandlers.close()]}
            >
              {label}
            </button>
          ))}
        </div>
      )}
    </StyledTooltipMenu>
  );
};

interface TooltipMenuProps {
  label: string;
  options: { label: string; value: string }[];
  onChange: (value: string) => void;
  value: string | null;
}
