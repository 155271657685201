import styled from 'styled-components';
import { desktopHover, pxtorem } from '~/utils/tools';

export const StyledDownlineDesktop = styled.div`
  --levelWidth: 72px;
  --statWidth: 104px;
  & .downline__grid {
    display: grid;
    grid-template-columns: var(--levelWidth) 1fr calc(var(--statWidth) + 24px) repeat(
        6,
        var(--statWidth)
      );
    place-items: center;
    align-items: stretch;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black}33;
    gap: 4px;

    span {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__header {
      font: 500 12px / 1.175 var(--primaryFont);
      padding-bottom: ${pxtorem(24)};
      text-transform: uppercase;
    }

    &__body {
      font: 400 16px / 1.175 var(--primaryFont);
      padding-block: ${pxtorem(16)};

      .level {
        display: flex;
        align-items: center;
        gap: 8px;

        &__button {
          display: grid;
          place-items: center;
          width: 24px;
          height: 24px;
          padding: 0;
          background: none;
          border: none;
          background-color: ${({ theme }) => theme.colors.lime};
          color: ${({ theme }) => theme.colors.green};
          border-radius: 50%;
          cursor: pointer;
          visibility: hidden;

          ${({ theme }) =>
            desktopHover(`
            background-color: ${theme.colors.green};
            color: ${theme.colors.white};
          `)}

          &:disabled {
            color: #a4a4a4;
            background-color: #d9d9d9;
          }

          svg {
            margin-top: 2px;
            transform: rotate(-90deg);
            transition: transform 0.3s var(--cubic-ease);
          }
          &--visible {
            visibility: visible;
          }
          &--open {
            background-color: ${({ theme }) => theme.colors.green};
            color: ${({ theme }) => theme.colors.white};

            svg {
              transform: rotate(0deg);
            }
          }
        }

        &__indicator {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 1px solid ${({ theme }) => theme.colors.black}33;
          display: grid;
          place-items: center;
        }
      }

      .ambassador {
        display: grid;
        place-items: center;

        &__name::after {
          position: relative;
          left: 12px;
          transform: translateY(2px);
          background-color: ${({ theme }) => theme.colors.gray};
          height: 18px;
          width: 18px;
          text-align: center;
          font: 400 12px / 16px var(--primaryFont);
        }
      }
    }
  }

  @media (max-width: 1280px) {
    --levelWidth: 64px;
    --statWidth: 84px;
  }
`;

export const StyledDownlineDesktopRow = styled.div<{
  level: number;
  childCount: number;
}>`
  margin-left: ${({ level }) => `calc((var(--levelWidth) - 32px) * ${level})`};

  .ambassador__name::after {
    content: '${({ childCount }) => childCount}';
  }

  .ambassador {
    grid-template-columns: ${({ level }) => ([0, 1].includes(level) ? '1fr 1fr' : '1fr')};
    place-items: center;
    padding-right: 8px;
  }

  .order-detail-btn {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.orange};
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    min-width: 124px;
    padding-right: 8px;
    justify-self: end;
  }
`;

export const StyledDownlineCTARow = styled.div`
  grid-column: span 9;
  padding-block: ${pxtorem(20)};
  padding-inline: ${pxtorem(64)};
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: calc((var(--levelWidth) - 32px) * 3);
  border-bottom: 1px solid ${({ theme }) => theme.colors.black}33;

  p {
    font: 500 ${pxtorem(20)} / 1.2 var(--primaryFont);
    margin: 0;
  }

  button {
    font-weight: 500;
  }
`;
