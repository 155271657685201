import { Link } from 'gatsby'
import {
  useAuth,
  useTranslate,
  useUser,
} from '@chordcommerce/gatsby-theme-autonomy'
import { Cta } from '~/components/Generic/Cta/Cta'
import { useEffect } from 'react'

const AccountPage = () => {
  const translate = useTranslate()
  const { user, loadUser } = useUser()
  const { logout } = useAuth()
  useEffect(() => {
    setTimeout(loadUser, 500);
  }, [])

  return (
    <div className="AccountPage">
      <div>
        <h1>
          {translate('account.page_title')}
        </h1>
        <div className="AccountPage__actions">
          <Cta href="/account/profile">
            {translate('account.edit_profile')}
          </Cta>
          <Cta href="/account/orders">
            {translate('account.orders')}
          </Cta>
          <Cta href="/account/subscriptions">
            {translate('account.subscriptions')}
          </Cta>
          {user?.data?.roles?.includes?.('ambassador') && (
            <Cta href="/account/ambassador">
              Ambassador Portal
            </Cta>
          )}
          <Cta className="AccountPage__logout" onClick={logout}>{translate('account.logout')}</Cta>
        </div>
      </div>
    </div>
  )
}

export default AccountPage
