import { useMediaQuery } from '@mantine/hooks';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DownlineDesktop } from '~/components/Account/AmbassadorPortal/Downline/DownlineDesktop';
import { DownlineMobile } from '~/components/Account/AmbassadorPortal/Downline/DownlineMobile';

export const Downline: React.FC<DownlineProps> = ({
  downline,
  onOpenDetails,
  downloadReport
}) => {
  const isMobile = useMediaQuery('(max-width: 1120px)');
  const nodes = useMemo(() => {
    if (!downline) return [];

    const remaining = downline.filter((n: any) => n.level === 0);
    const ordered: any[] = [];

    while (remaining.length > 0) {
      const node = remaining.shift();
      node.children = downline.filter((n: any) => n.parentID === node.nodeID);
      remaining.push(...node.children);
      if (node.level === 0) {
        ordered.push(node);
      }
    }

    // sort every items children so that the nodes with the most children are last
    ordered.forEach((node: any) => {
      node.children.sort((a: any, b: any) => {
        if (a.children.length > b.children.length) return -1;
        if (a.children.length < b.children.length) return 1;
        return 0;
      });
    });
    return ordered;
  }, [downline]);

  return (
    <StyledDownline>
      {isMobile ? (
        <DownlineMobile nodes={nodes} onOpenDetails={onOpenDetails} />
      ) : (
        <DownlineDesktop nodes={nodes} onOpenDetails={onOpenDetails} downloadReport={downloadReport} />
      )}
    </StyledDownline>
  );
};

interface DownlineProps {
  downline?: any[];
  onOpenDetails: (ambassador: any) => void;
  downloadReport?: () => void;
}

const StyledDownline = styled.div`
  padding-bottom: calc(var(--gutter) * 2);
`;
