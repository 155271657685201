import { useUser } from '@chordcommerce/gatsby-theme-autonomy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Cta } from '~/components/Generic/Cta/Cta';
import Loader from '~/components/Loader/Loader';
import { Wrapper } from '~/styles/Wrapper.styled';
import { StyledAmbassadorOrders } from './AmbassadorOrders.style';
import { useMediaQuery } from '@mantine/hooks';
import { AmbassadorOrdersMobile } from '~/components/Account/AmbassadorPortal/OrdersPage/AmbassadorOrdersMobile';
import { paymentTypesMap, shippingStatusesMap } from '~/types/constants';
import { Tooltip, TooltipProps } from '@mantine/core';
import theme from '~/styles/theme';
import { exigo } from '~/utils/exigo';
import { exportToCsv, formatPhoneNumber } from '~/utils/tools';

const tooltipProps: Partial<TooltipProps> = {
  withArrow: true,
  className: 'tooltip',
  style: { textTransform: 'none', fontFamily: 'var(--primaryFont)' },
  color: theme.colors.green,
  position: 'top-start',
  transition: 'pop',
};



export const AmbassadorOrders = ({}) => {
  const { user } = useUser();
  const name =
    (user as any)?.data?.name || (user as any)?.data?.shipAddress?.name || '';
  const [isLoading, setIsLoading] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingText, setLoadingText] = useState('Loading orders...');
  const [orders, setOrders] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [downline, setDownline] = useState<any[]>([]);


  useEffect(() => {
    const fetchDownline = async () => {
      const downline = await exigo(
        `downline?ambassadorId=${user.id}`
      );
      setDownline(downline.nodes);
    };
    fetchDownline();
  }, []);

  console.log({downline})
  useEffect(() => {
    console.log('Current page changed');
    const fetchOrders = async () => {
      setIsLoading(true);
      const chordOrders = await exigo(
        `downline-orders?page=${currentPage}`
      )
      setOrders(chordOrders);
      setIsLoading(false);
    };
    fetchOrders();
  }, [currentPage]);
  const ambassador = useMemo<any>(() => {
    return downline[0] || {};
  }, [downline])

  const runExport = useCallback(async () => {
    console.log('runExport', ambassador, loadingExport);
    if (loadingExport) return;
    setLoadingExport(true);
    const allOrders = [];
    let hasMoreOrders = false;
    let page = 1;
    let totalOrders = 0;
    do {
      const percentage = Math.round(
        (allOrders.length / (totalOrders || 1)) * 100
      );
      setLoadingText(`Exporting orders ${percentage}%`);
      const url = `downline-orders?page=${page}&ambassadorId=${ambassador.customerID}`;
      const response = await exigo(url);
      const { orders, current_page, pages, count } = response;
      totalOrders = count;
      allOrders.push(...orders);
      hasMoreOrders = page < pages;
      ++page;
    } while (hasMoreOrders && page < 25);
    console.log({ allOrders });
    setLoadingText(`Generating report...`);
    const lines = [
      [
        'Order detail by Ambassador',
        'Generated on ' + new Date().toLocaleString(),
      ],
      [],
      ['Ambassador', `${ambassador.firstName} ${ambassador.lastName}`],
    ];
    if (ambassador.email) {
      lines.push(['Email', ambassador.email]);
    }
    if (ambassador.phone) {
      lines.push(['Phone', ambassador.phone]);
    }
    if (ambassador.customerID) {
      lines.push(['ID', ambassador.customerID]);
    }
    lines.push([]);
    lines.push([
      'Order Date',
      'Order Number',
      'Tracking',
      'Type',
      'Status',
      'Name',
      'Email',
      'Address',
      'Phone',
      'QV',
      'Subscription',
      'Cart',
    ]);
    allOrders.forEach(order => {
      const shipments = (order.shipments || []).filter(
        (ship: any) => ship.tracking
      );
      lines.push([
        new Date(order.completed_at).toLocaleDateString(),
        order.number,
        shipments.map((s:any) => s.tracking_url).join('\n'),
        order.order_type,
        paymentTypesMap[order.payment_state] || order.payment_state,
        order.ship_address.name,
        order.email,
        [order.ship_address.address1, order.ship_address.address2, order.ship_address.city, order.ship_address.state_text, order.ship_address.zipcode].filter(Boolean).join(', '),
        order.ship_address.phone ? formatPhoneNumber(order.ship_address.phone) : '',
        (1 * order.qualifyingVolume).toFixed(2),
        order.subscription_status,
        order.simplified_line_items.map((v: any) => `- ${v.quantity}x ${v?.text || 'Unknown item'}`).join('\n'),
      ]);
    });
    exportToCsv(`orders-${ambassador.customerID}.csv`, lines);
    setLoadingExport(false);
    setLoadingText(``);
  }, [loadingExport, ambassador]);


  return (
    <Wrapper width="normal" gutter={false}>
      <StyledAmbassadorOrders>
        <div className="AmbassadorOrders__header">
          <h1>
            Welcome back <em>{(name || '').split(' ').shift()}</em>
          </h1>
          <div className="AmbassadorOrders__headerActions">
            {/* <Cta variant="primary" href="/account/ambassador/order">
              Start a customer order
            </Cta> */}
            <Cta href="/shop" variant="primary">Start a personal order</Cta>
          </div>
        </div>
        <div className="AmbassadorOrders__content">
          <div className="AmbassadorOrders__content__heading">
            <h2>Orders</h2>
            <Cta disabled={loadingExport} onClick={() => runExport()}>
            {loadingExport ? loadingText : <>Download full report</>}
          </Cta>
          </div>
          {isLoading && <Loader text="Gathering orders..." />}
          {!isLoading &&
            (isMobile ? (
              <AmbassadorOrdersMobile orders={orders.orders} />
            ) : (
              <AmbassadorOrdersDesktop orders={orders.orders} />
            ))}

          <div className="AmbassadorOrders__pagination">
            {orders.pages > 1 && (
              <ul>
                {Array.from({ length: orders.pages }).map((_, i) => (
                  <li key={i}>
                    <button
                      className={i + 1 === currentPage ? 'current' : ''}
                      onClick={() => {
                        if (currentPage !== i + 1) {
                          setCurrentPage(i + 1);
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </StyledAmbassadorOrders>
    </Wrapper>
  );
};

const AmbassadorOrdersDesktop: React.FC<AmbassadorOrdersDesktopProps> = ({
  orders,
}) => {
  const { user } = useUser();
  const [openIdx, setOpenIdx] = useState(-1);
  return (
    <table className="AmbassadorOrders__orders">
      <thead>
        <tr>
          <th>Order Date</th>
          <th>Order ID</th>
          <th>Tracking</th>
          <th>Type</th>
          <th>Status</th>
          <th>Name</th>
          <th>Email</th>
          <th>Address</th>
          <th>Phone #</th>
          <th>QV</th>
          <th>Subscription</th>
          <th>Cart</th>
        </tr>
      </thead>
      <tbody>
        {orders?.map((order: any, i: number) => {
          const shipments = (order.shipments || []).filter(
            (ship: any) => ship.tracking
          );
          const isOpen = openIdx === i;
          return (
            <>
              <tr className={isOpen ? 'open' : ''}>
                <td>{format(new Date(order.completed_at), 'MM/dd/yyyy')}</td>
                <td>{order.number.split('-').pop()}</td>
                <td>
                  {shipments.map((s: any) => {
                    if (s.tracking_url) {
                      return (
                        <a
                          href={s.tracking_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Track
                        </a>
                      );
                    }

                    return (
                      <div>
                        {shippingStatusesMap[order.shipment_state] ||
                          order.shipment_state}
                      </div>
                    );
                  })}
                </td>
                <td>
                  <span>
                  {order.order_type}
                  </span>
                </td>
                <td>
                  {paymentTypesMap[order.payment_state] || order.payment_state}
                </td>
                <td>{order.ship_address.name}</td>
                <td className="with-tooltip">
                  <Tooltip label={order.email} {...tooltipProps}>
                    <span>{order.email} </span>
                  </Tooltip>
                </td>
                <td className="with-tooltip">
                  <Tooltip
                    label={[order.ship_address.address1, order.ship_address.address2, order.ship_address.city, order.ship_address.state_text, order.ship_address.zipcode].filter(Boolean).join(', ')}
                    {...tooltipProps}
                  >
                    <span>{[order.ship_address.address1, order.ship_address.address2, order.ship_address.city, order.ship_address.state_text, order.ship_address.zipcode].filter(Boolean).join(', ')}</span>
                  </Tooltip>
                </td>
                <td>{order.ship_address.phone ? formatPhoneNumber(order.ship_address.phone) : ''}</td>
                <td>${(1 * order.qualifyingVolume).toFixed(0)}</td>
                <td>{order.subscription_status}</td>
                <td>
                  {order.simplified_line_items?.length > 0 && (
                    <Cta
                      variant="secondary"
                      className="cart-btn"
                      onClick={() => {
                        if (isOpen) {
                          setOpenIdx(-1);
                          return;
                        }
                        setOpenIdx(i);
                      }}
                    >
                      View Cart
                    </Cta>
                  )}
                </td>
              </tr>
              {isOpen && (
                <tr className="line_items">
                  <td colSpan={11}>
                    <div className="line_items__content">
                      {order.simplified_line_items?.map((item: any) => (
                        <div className="line_items__item">
                          <span>{item.quantity}x</span>
                          <span>{item.text}</span>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              )}
            </>
          );
        })}
      </tbody>
    </table>
  );
};

interface AmbassadorOrdersDesktopProps {
  orders: any[];
}
