import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { Router } from '@reach/router';
import AccountLoginPage from '../components/Account/Login/Page';
import PrivateRoute from '../components/Account/PrivateRoute';
import AccountProfilePage from '../components/Account/Profile/Page';
import AccountSubscriptionPage from '../components/Account/Subscription/Page';
import AccountSubscriptionDetailPage from '../components/Account/Subscription/Detail/Page';
import AccountPage from '../components/Account/Page';
import { Wrapper } from '~/styles/Wrapper.styled';
import { StyledAccount } from './account.styled';
import { AmbassadorPortal } from '~/components/Account/AmbassadorPortal/AmbassadorPortal';
import { AmbassadorOrders } from '~/components/Account/AmbassadorPortal/OrdersPage/AmbassadorOrders';
import { Orders as AccountOrders } from '~/components/Account/Orders/Orders';
import { SingleOrder as AccountOrder } from '~/components/Account/Orders/SingleOrder';
import { AmbassadorCreateOrder } from '~/components/Account/AmbassadorPortal/AmbassadorCreateOrder';
import { AmbassadorNews } from '~/components/Account/AmbassadorPortal/AmbassadorNews';
import { AmbassadorPerformance } from '~/components/Account/AmbassadorPortal/AmbassadorPerformance';

const Account = () => {
  const translate = useTranslate();

  return (
    <Layout showFooterMarquee={false}>
      <Metadata title={translate('account.page_title')} />
      <Wrapper allowOverflow width="normal">
        <StyledAccount>
          <Router basepath="/account">
            <AccountLoginPage path="/login" />
            <PrivateRoute
              showBackButton={true}
              path="/profile"
              component={AccountProfilePage}
            />
            <PrivateRoute
              showBackButton={true}
              path="/orders"
              component={AccountOrders}
            />
            <PrivateRoute
              showBackButton={true}
              path="/order"
              component={AccountOrder}
            />
            <PrivateRoute
              showBackButton={true}
              path="/subscriptions"
              component={AccountSubscriptionPage}
            />
            <PrivateRoute
              showBackButton={true}
              path="/subscriptions/:subscriptionId"
              component={AccountSubscriptionDetailPage}
            />
            <PrivateRoute path="/" component={AccountPage} />
            <PrivateRoute
              href="/account/login?ambassador"
              requireRole="ambassador"
              path="/ambassador"
              component={AmbassadorPortal}
            />
            <PrivateRoute
              href="/account/login?ambassador"
              requireRole="ambassador"
              path="/ambassador/orders"
              component={AmbassadorOrders}
            />
            <PrivateRoute
              href="/account/login?ambassador"
              requireRole="ambassador"
              path="/ambassador/performance"
              component={AmbassadorPerformance}
            />
            <PrivateRoute
              href="/account/login?ambassador"
              requireRole="ambassador"
              path="/ambassador/order"
              component={AmbassadorCreateOrder}
            />
            <PrivateRoute
              href="/account/login?ambassador"
              requireRole="ambassador"
              path="/ambassador/news"
              component={AmbassadorNews}
            />
          </Router>
        </StyledAccount>
      </Wrapper>
    </Layout>
  );
};

export default Account;
