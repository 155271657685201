import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledTooltipMenu = styled.div`
  position: relative;

  & button {
    --size: 1.8em;
    appearance: none;
    background: transparent;
    box-sizing: border-box;
    display: inline-block;
    font-size: ${pxtorem(20)};
    border: none;
    padding: 10px 20px;
    line-height: calc(var(--size) - 2px);
    text-align: center;
    cursor: pointer;
    transition-property: background-color;
    transition-timing-function: var(--cubic-ease);
    transition-duration: 0.3s;
    
    &:hover {
      background-color: var(--lime);
    }

    @media (max-width: 768px) {
      font-size: ${pxtorem(16)};
    }
  }

  & .tooltip {
    --color: var(--darkGray);
    /* padding: 45px 30px 30px; */
    color: var(--color);
    overflow: hidden;
    border: 1px solid var(--color);
    border-radius: 20px;
    background: var(--white);
    width: 90vw;
    max-width: 250px;
    z-index: 20;
    display: flex;
    align-items: stretch;
    flex-direction: column;


    & p {
      margin: 0;

      & + p {
        margin-top: 1em;
      }
    }

    & .close {
      position: absolute;
      top: 12px;
      right: 25px;
      font-size: 26px;
    }
  }

  @media (min-width: 768px) {
    & .tooltip {
      /* padding-top: 30px; */

      & .close {
        display: none;
      }
    }
  }
`;
