import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { Any } from '../../../../../types/types'
import { Cta } from '~/components/Generic/Cta/Cta'

const SubscriptionDetailsPauseForm: React.FC<Any> = ({ action, actionType }) => {
  const translate = useTranslate()
  const { register, handleSubmit } = useForm()
  const [error, setError] = useState(null)

  const onSubmit = async (actionable_date: Any) => {
    await action(actionable_date).catch((error: Any) => {
      setError(error)
    })
  }

  return (
    <form
      
      onSubmit={handleSubmit(onSubmit)}
    >
      <Cta variant="secondary" type="submit">{translate(`subscriptions.${actionType}`)}</Cta>
      <p>
        {actionType === 'pause'
          ? translate('subscriptions.pause_until')
          : translate('subscriptions.resume_at')}
      </p>
      <input type="date" ref={register()} name="actionable_date" />
      {error && <p>{error.message}</p>}
    </form>
  )
}
export default SubscriptionDetailsPauseForm
