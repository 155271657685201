import { FC, useState } from 'react'
import SubscriptionDetailsAddressForm from '~/components/Account/Subscription/Detail/AddressForm'
import BillingSummary from '~/components/Account/Subscription/Detail/BillingSummary'
import { Any } from '../../../../../types/types'

const BillingCard: FC<BillingCardProps> = ({ title, subscription }) => {
  const [isFormOpen, setIsFormOpen] = useState(false)

  return (
    <div>
      <h1>{title}</h1>

      {isFormOpen ? (
        <SubscriptionDetailsAddressForm
          addressType={'billAddress'}
          subscription={subscription}
          close={() => setIsFormOpen(false)}
        />
      ) : (
        <BillingSummary
          openForm={() => setIsFormOpen(true)}
          subscription={subscription}
        />
      )}
    </div>
  )
}

export default BillingCard

interface BillingCardProps {
  title: string
  subscription: Any;
}