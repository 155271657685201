import React, { useCallback, useEffect, useState } from "react";
import { StyledAmbassadorHeader } from "./AmbassadorHeader.style";
import { StaticImage } from "gatsby-plugin-image";
import { useUser } from "@chordcommerce/gatsby-theme-autonomy";
import { Cta } from "~/components/Generic/Cta/Cta";
import Star from '../../../assets/images/icons/star.svg';
import ShowOnScroll from "~/components/Generic/ShowOnScroll/ShowOnScroll";
import { exigo } from "~/utils/exigo";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const AmbassadorHeader = () => {
  const { user } = useUser();
  const joinDate = new Date(user?.data?.metadata?.ambassadorRoleAssignedAt || user?.data?.metadata?.becameAmbassadorAt || user?.data?.createdAt || null);
  const joinDateText = joinDate && joinDate >= new Date('2000-01-01') ? `${months[joinDate.getMonth()]} ${joinDate.getDate()}, ${joinDate.getFullYear()}` : '';
  const [rank, setRank] = useState(null);
  const rankStars = rank ? rank.match(/[0-9]+$/)?.[0] || 1 : 0;
  const [hasCopied, setHasCopied] = useState(false);
  const name = (`${user?.data?.metadata?.firstName || ''} ${user?.data?.metadata?.lastName || ''}`.trim()) || user?.data?.name || user?.data?.shipAddress?.name || user?.data?.billAddress?.name
  useEffect(() => {
    const fetchRank = async () => {
      const myVolumes: any = await exigo('volumes');
      const currentVolume = myVolumes?.volumes?.length ? myVolumes.volumes[0] : {};
      const rank = await exigo(`qualification${currentVolume?.paidRankID ? `?rankId=${currentVolume.paidRankID}` : ''}`);
      setRank(`${rank?.rankDescription || ''}`);
    }
    fetchRank();
  }, [])

  const copy = useCallback(() => {
    const slugify = (str: string) =>
      str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
    const path = `/with/${user.data.id}/${slugify(name || 'ambassador')}`
    navigator.clipboard.writeText(`${window.location.origin}${path}`);
    setHasCopied(true);
    setTimeout(() => {
      setHasCopied(false);
    }, 3000);
  }, [user]);
  return (
    <ShowOnScroll>
      <StyledAmbassadorHeader>
        <div className="AmbassadorHeader__content">
          <h1>Ambassador <em>Dashboard</em></h1>
          <div className="AmbassadorHeader__facts">
            <div>{name}</div>
            <div>{joinDateText}</div>
            <div>{rank && (<>{Array.of(rankStars).map((v: any, i: number) => <Star key={i}/>)} {rank}</>)}</div>
          </div>
          <div className="AmbassadorHeader__links">
            <Cta href="/shop">Start a personal order</Cta>
            <Cta variant="primary" href="/account/ambassador/order">Create & Share a Customer Wishlist</Cta>
            <Cta variant="secondary" onClick={copy} className="AmbassadorHeader__copy">
              <span className={`AmbassadorHeader__copiedNotification ${hasCopied ? 'shown' : ''}`}>The URL has been copied to your clipboard!</span>
              Copy your link to share Commons</Cta>
          </div>

        </div>
        <div className="AmbassadorHeader__image">
          <StaticImage src="../../../assets/images/ambassador-portal-header.png" alt="Ambassador Portal" />
        </div>
      </StyledAmbassadorHeader>
    </ShowOnScroll>
  );
}